import {
	BaseQueryFn,
	createApi,
	FetchArgs,
	fetchBaseQuery,
	FetchBaseQueryError,
	retry
} from '@reduxjs/toolkit/query/react';
import { RootState } from '../store';
import config from '../common/config';
import {AUTH401_ACTION_TYPE, SWAP_TOKEN_URL_PATH} from '../constants';

const AUTHORIZATION_HEADER_NAME = 'Authorization';

const baseQuery = fetchBaseQuery({
	baseUrl: config.remoteApiAccountsUrl,
	prepareHeaders: (headers, { getState }) => {
		const token = (getState() as RootState).auth.token;
		if (token) {
			headers.set(AUTHORIZATION_HEADER_NAME, `Bearer ${token}`);
		}
		return headers;
	},
});

const baseQueryWith401Handler: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
	const result = await baseQuery(args, api, extraOptions);

	// generic handler for all 401s to trigger access token swap. Token swap in turn has a custom error to clear auth0 token.
	if (result.error && result.error.status === 401 && (args as FetchArgs)?.url !== SWAP_TOKEN_URL_PATH) {
		api.dispatch({ type: AUTH401_ACTION_TYPE });
	}

	return result;
};

const baseQueryWithRetry = retry(baseQueryWith401Handler, { maxRetries: 1 });

/**
 * Create a base API to inject endpoints into elsewhere.
 * Components using this API should import from the injected site,
 * in order to get the appropriate types,
 * and to ensure that the file injecting the endpoints is loaded
 */
export const api = createApi({
	/**
   * `reducerPath` is optional and will not be required by most users.
   * This is useful if you have multiple API definitions,
   * e.g. where each has a different domain, with no interaction between endpoints.
   * Otherwise, a single API definition should be used in order to support tag invalidation,
   * among other features
   */
	reducerPath: 'splitApi',
	/**
   * A bare bones base query would just be `baseQuery: fetchBaseQuery({ baseUrl: '/' })`
   */
	baseQuery: baseQueryWithRetry,
	/**
   * Tag types must be defined in the original API definition
   * for any tags that would be provided by injected endpoints
   */
	tagTypes: ['Accounts'],
	/**
   * This api has endpoints injected in adjacent files,
   * which is why no endpoints are shown below.
   * If you want all endpoints defined in the same file, they could be included here instead
   */
	endpoints: () => ({}),
});
