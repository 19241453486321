import * as React from 'react';
import {FormEvent, useCallback, useEffect, useMemo, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {DialogContentText, DialogTitle} from '@mui/material';
import {css} from '@emotion/css';
import {Entry} from '../types';
import {useAppSelector} from '../hooks';
import {
	recordDeleteLocalAction, selectEntryReadableValues
} from '../containers/contentSlice';
import {useDispatch} from 'react-redux';
import {entryGetReadableValue} from '../common/util';
import {useEntryDeleteMutation} from '../services/records';
import {selectIsAuthenticated} from '../containers/authSlice';
import {
	selectConfirmDeleteDialogEntry,
	selectIsConfirmDeleteDialogOpen,
	confirmDeleteDialogEntrySetState
} from '../containers/formDataSlice';

export const useConfirmDeleteEntryDialog = () => {
	const dispatch = useDispatch();
	const isConfirmDeleteDialogOpen = useAppSelector(selectIsConfirmDeleteDialogOpen);
	const confirmDeleteDialogEntry = useAppSelector(selectConfirmDeleteDialogEntry);
	const [entryDeleteMutation] = useEntryDeleteMutation();
	const isAuthenticated = useAppSelector(selectIsAuthenticated);

	const closeConfirmDeleteDialog = () => {
		dispatch(confirmDeleteDialogEntrySetState(null));
	};

	const openConfirmDeleteDialog = useCallback((entry: Entry) => {
		dispatch(confirmDeleteDialogEntrySetState(entry));
	}, []);

	const submitConfirmDeleteDialog = useCallback(() => {
		const {id, type} = confirmDeleteDialogEntry as Entry;
		if (isAuthenticated) {
			entryDeleteMutation(id);
		} else {
			dispatch(recordDeleteLocalAction(id));
		}
		closeConfirmDeleteDialog();
	}, [confirmDeleteDialogEntry]);

	return {isConfirmDeleteDialogOpen, confirmDeleteDialogEntry, openConfirmDeleteDialog, closeConfirmDeleteDialog, submitConfirmDeleteDialog};
};

interface Props {
	open: boolean;
	handleClose: () => void;
	handleSubmit: () => void;
	entry: Entry | null;
	title: string;
}

function ConfirmDeleteEntryDialog({ open, handleClose, handleSubmit, entry, title}: Props) {
	const [formData, setFormData] = useState<Entry | null>(null);
	const entryReadableValues = useAppSelector(selectEntryReadableValues);
	const entryTitle = useMemo(() => formData ? entryGetReadableValue(formData, entryReadableValues) : '', [formData, entryReadableValues]);
	const [dialogTitle, setDialogTitle] = useState<string | null>(null);

	// cache content to prevent it from disappearing during dialog fade out
	useEffect(() => {
		if (open) {
			setFormData(entry);
			setDialogTitle(title);
		}
	}, [open]);

	const onSubmit = (e: FormEvent) => {
		e.preventDefault();
		handleSubmit();
	};

	return (
		<Dialog open={open} onClose={handleClose} className={css`
			& .MuiPaper-root {
			  min-width: 320px;
			}
		`}>
			<DialogTitle>
				{dialogTitle}
			</DialogTitle>
			<form onSubmit={onSubmit} autoComplete='off'>
				<DialogContent>
					<DialogContentText>
						Are you sure your want to delete &quot;{entryTitle}&quot;?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Cancel</Button>
					<Button type='submit' variant="contained" color='error'>Delete</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
}

export default ConfirmDeleteEntryDialog;
