import Cookies from 'js-cookie';

const ACCESS_TOKEN_NAME = 'accessToken';

export function storeAccessToken(token: string) {
	console.debug('storing cookie');
	Cookies.set(ACCESS_TOKEN_NAME, token, { expires: 1, secure: true });
}

export function removeAccessToken() {
	Cookies.remove(ACCESS_TOKEN_NAME);
}

export function getAccessToken(): string | null {
	return Cookies.get(ACCESS_TOKEN_NAME) ?? null;
}
