import {EntityType, Repository, PropertyDefinition} from '../types';
import {REPOSITORY_REPOSITORY} from './repository';

export const TEMPLATE_PROPERTY_BELONGS_TO_REPOSITORY: Repository = {
	id: 'idPropertyDefinitionBelongsToRepository',
	parentId: null,
	type: EntityType.Repository,
	title: 'Relations (belongs to)',
	singular: 'Relation (belongs to)',
};

export const TEMPLATE_PROPERTY_BELONGS_TO_TEMPLATE_PROPERTIES: PropertyDefinition[] = [{
	id: 'idPropertyDefinitionBelongsToLabelProperty',
	parentId: TEMPLATE_PROPERTY_BELONGS_TO_REPOSITORY.id,
	type: EntityType.PropertyDefinitionString,
	slug: 'label',
	label: 'Label',
	required: true,
	autoFocus: true,
}, {
	id: 'idPropertyDefinitionBelongsToSlugProperty',
	parentId: TEMPLATE_PROPERTY_BELONGS_TO_REPOSITORY.id,
	type: EntityType.PropertyDefinitionString,
	slug: 'slug',
	label: 'Key',
	required: true,
	disabled: true,
}, {
	id: 'idPropertyDefinitionBelongsToTargetRepositoryIdProperty',
	parentId: TEMPLATE_PROPERTY_BELONGS_TO_REPOSITORY.id,
	type: EntityType.PropertyDefinitionBelongsTo,
	slug: 'targetRepositoryId',
	targetRepositoryId: REPOSITORY_REPOSITORY.id,
	label: 'Other repository',
	required: true,
}, {
	id: 'idPropertyDefinitionBelongsToRequiredProperty',
	parentId: TEMPLATE_PROPERTY_BELONGS_TO_REPOSITORY.id,
	type: EntityType.PropertyDefinitionBoolean,
	slug: 'required',
	label: 'Required',
	required: true,
}, {
	id: 'idPropertyDefinitionBelongsToAllowManyProperty',
	parentId: TEMPLATE_PROPERTY_BELONGS_TO_REPOSITORY.id,
	type: EntityType.PropertyDefinitionBoolean,
	slug: 'allowMany',
	label: 'Allow many',
	required: true,
}];
