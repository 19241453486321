import {put, select, takeLatest} from 'redux-saga/effects';
import {
	entriesAddMany,
	entriesDeleteMany,
	entriesUpdateMany,
	recordDeleteLocalAction,
	repositoryEntryUpdateLocalAction,
	selectAllEntries,
	selectLocationEntry,
} from './contentSlice';
import {
	isNavigateOutwardInitiatedSetState,
	navigateInwardTargetIdSetState,
	navigateRollInward as navigateRollInwardAction,
	navigateRollOutward as navigateRollOutwardAction,
	redirectToPathSetState,
	selectIsNavigateOutwardInitiated,
	selectNavigateInwardTargetId,
} from './navigationSlice';

import {AnyAction} from 'redux';
import {
	EntityType,
	Entry,
	EntryProperty,
	EntryPropertyBelongsTo,
	PropertyDefinition,
	PropertyDefinitionBelongsTo, PropertyDefinitionHas,
	RepositoryEntry
} from '../types';
import {
	assignRandomId,
	findEntryPropertiesWithUnknownTargetEntry,
	findPropertiesWithUnknownDefinitions,
	getEntryProperties,
	recursiveChildRecordIdsFind,
	getEntryRemoteProperties
} from '../common/util';
import {PROPERTY_DEFINITION_TYPES, PROPERTY_DEFINITION_TYPES_TO_ENTRY_PROPERTY_TYPES} from '../constants';

interface NavigateAction extends AnyAction {
	payload: {
		focusId: string;
	};
}

interface RepositoryEntryUpdateLocalAction extends AnyAction {
	payload: { id: string, values: {[key: string]: any} };
}

interface RecordDeleteLocalAction extends AnyAction {
	payload: string;
}

function* navigateRollOutward(): Generator<any, void, any> {
	const locationEntry = yield select(selectLocationEntry);
	const navigateInwardTargetId = yield select(selectNavigateInwardTargetId);
	const isNavigateOutwardInitiated = yield select(selectIsNavigateOutwardInitiated);
	const parentId = locationEntry?.parentId ?? null;
	if (navigateInwardTargetId) {
		yield put(navigateInwardTargetIdSetState(null));
	} else if (locationEntry && !isNavigateOutwardInitiated) {
		yield put(isNavigateOutwardInitiatedSetState(true));
	} else if (locationEntry) {
		yield put(redirectToPathSetState(`/${parentId ? parentId : ''}`));
	}
}

function* navigateRollInward(action: NavigateAction): Generator<any, void, any> {
	const navigateInwardTargetId = yield select(selectNavigateInwardTargetId);
	if (action.payload.focusId && navigateInwardTargetId) {
		yield put(redirectToPathSetState(`/${navigateInwardTargetId}`));
	}
}

function getEntryPropertyUpdates(
	entry: RepositoryEntry,
	def: PropertyDefinition,
	existingProperties: EntryProperty[],
	value: string | boolean,
): {
	toUpdate: EntryProperty[];
	toCreate: Partial<EntryProperty>[];
} {
	const toCreate: Partial<EntryProperty>[] = [];
	const toUpdate: EntryProperty[] = [];
	const [existingProperty] = existingProperties;
	if (existingProperty) {
		if (existingProperty.value !== value) {
			toUpdate.push({ ...existingProperty, value } as EntryProperty);
		}
	} else {
		toCreate.push(assignRandomId({
			parentId: entry.id,
			repositoryId: entry.repositoryId,
			propertyDefinitionId: def.id,
			type: PROPERTY_DEFINITION_TYPES_TO_ENTRY_PROPERTY_TYPES[def.type],
			slug: def.slug,
			value,
		}));
	}
	return { toUpdate, toCreate };
}

function getEntryPropertyBelongsToUpdates(
	entry: RepositoryEntry,
	def: PropertyDefinitionBelongsTo,
	existingProperties: EntryPropertyBelongsTo[],
	values: string[],
): {
	toDelete: EntryPropertyBelongsTo[];
	toCreate: Partial<EntryPropertyBelongsTo>[];
} {
	const existingPropertiesByValues = existingProperties.reduce((acc, prop) => ({
		...acc,
		[prop.value]: prop
	}), {} as { [key: string]: EntryPropertyBelongsTo });
	const toDelete = existingProperties.filter(
		(existingProp) => !values.includes(existingProp.value),
	);
	const toCreate = values
		.filter((newValue) => newValue && !existingPropertiesByValues[newValue])
		.map((value) => assignRandomId({
			parentId: entry.id,
			type: EntityType.EntryPropertyBelongsTo,
			repositoryId: entry.repositoryId, // TODO: there is no such property!?!
			propertyDefinitionId: def.id,
			targetRepositoryId: def.targetRepositoryId,
			slug: def.slug,
			value,
		})) as Partial<EntryPropertyBelongsTo>[];
	return {toDelete, toCreate};
}

function getEntryPropertyHasUpdates(
	entry: RepositoryEntry,
	def: PropertyDefinitionHas,
	existingRemoteProperties: EntryPropertyBelongsTo[],
	values: string[],
): {
	toDelete: EntryPropertyBelongsTo[];
	toCreate: Partial<EntryPropertyBelongsTo>[];
} {
	const existingRemotePropertiesByParentIds = existingRemoteProperties.reduce((acc, prop) => ({
		...acc,
		[prop.parentId]: prop
	}), {} as { [key: string]: EntryPropertyBelongsTo });
	const toDelete = existingRemoteProperties.filter(
		(existingProp) => !values.includes(existingProp.parentId),
	);
	const toCreate = values
		.filter((newValue) => newValue && !existingRemotePropertiesByParentIds[newValue])
		.map((value) => assignRandomId({
			parentId: value,
			type: EntityType.EntryPropertyBelongsTo,
			repositoryId: def.targetRepositoryId,
			propertyDefinitionId: 'DELETE_ME', // TODO: get rid of this
			targetRepositoryId: def.parentId,
			slug: def.targetPropertySlug,
			value: entry.id,
		})) as Partial<EntryPropertyBelongsTo>[];
	return {toDelete, toCreate};
}

function* repositoryEntryUpdateLocal(action: RepositoryEntryUpdateLocalAction): Generator<any, void, any> {
	const { id, values } = action.payload;

	const allEntries = yield select(selectAllEntries);
	const entryToUpdate = allEntries.find((entry: Entry) => entry.type === EntityType.RepositoryEntry && entry.id === id);
	const propertyDefinitions = allEntries.filter((entry: Entry) => PROPERTY_DEFINITION_TYPES.includes(entry.type) && entry.parentId === entryToUpdate.repositoryId) as PropertyDefinition[];
	const entryProperties = getEntryProperties(id, allEntries);
	const entryRemoteProperties = getEntryRemoteProperties(id, allEntries);

	const entryCurrentValues = entryProperties.reduce((acc, prop) => {
		const currentValues = acc[prop.slug] ?? [];
		return {...acc, [prop.slug]: [...currentValues, prop]};
	}, {} as {[key:string]: EntryProperty[]});

	const recordsToCreate = [];
	const recordsToUpdate = [];
	const recordsToDelete = [];
	for (const def of propertyDefinitions) {
		const isPropertyIncludedInPayload = Object.keys(values).includes(
			def.slug,
		);
		if (!isPropertyIncludedInPayload) {
			continue;
		}
		const existingProperties = entryCurrentValues[def.slug] ?? [];
		const value = values[def.slug];
		if (def.type === EntityType.PropertyDefinitionBelongsTo) {
			const { toCreate, toDelete } = getEntryPropertyBelongsToUpdates(
				entryToUpdate,
				def as PropertyDefinitionBelongsTo,
				existingProperties as EntryPropertyBelongsTo[],
				value,
			);
			recordsToDelete.push(...toDelete);
			recordsToCreate.push(...toCreate);
		} else if (def.type === EntityType.PropertyDefinitionHas) {
			const existingRemoteProperties = entryRemoteProperties.filter(({ slug }) => (def as PropertyDefinitionHas).targetPropertySlug === slug);
			const { toCreate, toDelete } = getEntryPropertyHasUpdates(
				entryToUpdate,
				def as PropertyDefinitionHas,
				existingRemoteProperties as EntryPropertyBelongsTo[],
				value,
			);
			recordsToDelete.push(...toDelete);
			recordsToCreate.push(...toCreate);
		} else {
			const { toCreate, toUpdate } = getEntryPropertyUpdates(
				entryToUpdate,
				def,
				existingProperties,
				value,
			);
			recordsToCreate.push(...toCreate);
			recordsToUpdate.push(...toUpdate);
		}
	}
	yield put(entriesAddMany(recordsToCreate));
	yield put(entriesUpdateMany(recordsToUpdate));
	yield put(entriesDeleteMany(recordsToDelete.map(({ id }) => id)));
}

function* recordDeleteLocal(action: RecordDeleteLocalAction): Generator<any, void, any> {
	const allEntries: Entry[] = yield select(selectAllEntries);
	const entryToDelete = allEntries.find((entry) => entry.id === action.payload);
	if (!entryToDelete) {
		throw new Error('entry not found');
	}

	const idsToDelete: string[] = [action.payload, ...recursiveChildRecordIdsFind(action.payload, allEntries)];
	const allRemainingEntries = allEntries.filter(({ id }) => !idsToDelete.includes(id));

	if (PROPERTY_DEFINITION_TYPES.includes(entryToDelete.type)) {
		const propertiesWithUnknownDefinitions = findPropertiesWithUnknownDefinitions(allRemainingEntries);
		idsToDelete.push(...propertiesWithUnknownDefinitions);
	} else if (entryToDelete.type === EntityType.RepositoryEntry) {
		const entryPropertiesWithUnknownTargetEntry = findEntryPropertiesWithUnknownTargetEntry(allRemainingEntries);
		idsToDelete.push(...entryPropertiesWithUnknownTargetEntry);
	}

	yield put(entriesDeleteMany(idsToDelete));
}

function* appSaga() {
	yield takeLatest(navigateRollOutwardAction.type, navigateRollOutward);
	yield takeLatest(navigateRollInwardAction.type, navigateRollInward);
	yield takeLatest(recordDeleteLocalAction.type, recordDeleteLocal);
	yield takeLatest(repositoryEntryUpdateLocalAction.type, repositoryEntryUpdateLocal);
}

export default appSaga;