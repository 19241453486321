import {createSlice, createSelector} from '@reduxjs/toolkit';
import {accountsApi, UserInfo} from '../services/accounts';
import {removeAccessToken, storeAccessToken} from '../cookies';
import {RootState} from '../store';

export interface AuthState {
	user: UserInfo | null;
	token: string | null;
}

const initialState: AuthState = {
	user: null,
	token: null,
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		tokenSetState(state, action) {
			state.token = action.payload;
			return state;
		},
		resetState() {
			removeAccessToken();
			return initialState;
		},
	},
	extraReducers: (builder) => {
		builder
			.addMatcher(accountsApi.endpoints.swapToken.matchFulfilled, (state, action) => {
				state.token = action.payload.token;
				storeAccessToken(action.payload.token);
			})
			.addMatcher(accountsApi.endpoints.getUserInfo.matchFulfilled, (state, action) => {
				state.user = action.payload;
			})
			.addMatcher(accountsApi.endpoints.getUserInfo.matchRejected, authSlice.caseReducers.resetState); // triggers token swap
	},
});

export const {
	tokenSetState,
	resetState,
} = authSlice.actions;

const selectAuthSlice = (state: RootState) => state.auth;

export const selectAccessToken = createSelector([selectAuthSlice], (state: AuthState) => state.token);

export const selectUserProfile = createSelector([selectAuthSlice], (state: AuthState) => state.user);

export const selectIsAuthenticated = createSelector([selectAuthSlice], (state: AuthState) => state.user ? true : false);

export default authSlice.reducer;
