import {useCallback, useEffect, useRef} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {useAppSelector} from '../hooks';
import {
	isNavigateOutwardInitiatedSetState,
	navigateInwardTargetIdSetState,
	redirectToPathSetState,
	selectNavigateInwardTargetId,
	selectRedirectToPath,
} from '../containers/navigationSlice';

export function useContentNavigationEffects() {
	const focusIdRef = useRef<null | string>(null);
	const { id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const redirectToPath = useAppSelector(selectRedirectToPath);
	const navigateInwardTargetId = useAppSelector(selectNavigateInwardTargetId);

	const redirectResetState = () => {
		dispatch(redirectToPathSetState(null));
	};

	const navigateInwardsResetState = () => {
		dispatch(navigateInwardTargetIdSetState(null));
	};

	const navigateOutwardsResetState = () => {
		dispatch(isNavigateOutwardInitiatedSetState(false));
	};

	const setNavigateInwardTargetId = useCallback((id: string) => {
		if (navigateInwardTargetId !== id) {
			dispatch(navigateInwardTargetIdSetState(id));
		}
	}, []);

	const setFocusId = useCallback((id: string | null) => {
		focusIdRef.current = id;
	}, []);

	const handleEntryClick = useCallback((id: string) => {
		setNavigateInwardTargetId(id);
		navigateOutwardsResetState();
	}, []);

	const handleEntryFocus = useCallback((id: string) => {
		setFocusId(id);
		navigateInwardsResetState();
		navigateOutwardsResetState();
	}, []);

	const handleEntryBlur = useCallback(() => {
		setFocusId(null);
	}, []);

	useEffect(() => {
		if (redirectToPath) {
			navigate(redirectToPath);
			redirectResetState();
		}
	}, [navigate, redirectToPath]);

	useEffect(() => {
		focusIdRef.current = null;
		navigateInwardsResetState();
		navigateOutwardsResetState();
	}, [id]);

	return {focusIdRef, selectedEntryId: navigateInwardTargetId, handleEntryClick, handleEntryFocus, handleEntryBlur};
}
