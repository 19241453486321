import React, {useEffect} from 'react';
import {Box, CircularProgress} from '@mui/material';
import {
	entriesSetState,
	locationEntryLoaded,
	locationResetState,
	selectAllEntries,
	selectIsLocationEntryLoaded,
	selectIsLocationEntryLoading,
	selectLocationEntry,
	selectLocationEntryLocal,
	selectLocationId,
} from './contentSlice';
import {useAppDispatch, useAppSelector} from '../hooks';
import RepositoryView from './RepositoryView';
import useAuth, {useAuthEffects} from '../components/Auth';
import {obtainLocalEntries, storeLocalEntries} from '../localStorage';
import FooterNotification from '../components/FooterNotification';
import {useLoadEntryMutation} from '../services/records';
import {useParams} from 'react-router-dom';
import {EntityType} from '../types';
import GenericEntryView from './GenericEntryView';
import HeaderWrapper from '../components/HeaderWrapper';
import RepositoryHeaderContent from '../components/RepositoryHeaderContent';
import useNavigateToLocationId from '../components/NavigateToLocationId';
import AddButton, {AddButtonPadding} from '../components/AddButton';
import FabAreaWrapperBottomRight from '../components/FabAreaWrapperBottomRight';
import {useAddEntryForm} from '../components/AddEntryForm';
import ClipboardActionsFab from '../components/ClipboardActionsFab';
import GenericHeaderContent from '../components/GenericHeaderContent';
import RepositoryEntryView from './RepositoryEntryView';

function ContentWrapper() {
	useAuthEffects();
	const { id } = useParams();
	const {isAuthenticating, isAuthenticated} = useAuth();
	const navigateToLocationId = useNavigateToLocationId();
	const {openAddDialog} = useAddEntryForm();
	const dispatch = useAppDispatch();
	const allEntries = useAppSelector(selectAllEntries);
	const locationId = useAppSelector(selectLocationId);
	const locationEntry = useAppSelector(selectLocationEntry);
	const [loadEntryMutation, {error: loadEntryError}] = useLoadEntryMutation();
	const isLocationEntryLoading = useAppSelector(selectIsLocationEntryLoading);
	const isLocationEntryLoaded = useAppSelector(selectIsLocationEntryLoaded);
	const locationEntryLocal = useAppSelector(selectLocationEntryLocal);

	useEffect(() => {
		dispatch(locationResetState(id ?? null));
	}, [id]);

	// while not authenticated, init data from local storage
	useEffect(() => {
		if (isAuthenticating || isAuthenticated) {
			return;
		}
		const browserStorageEntries = obtainLocalEntries();
		dispatch(entriesSetState(browserStorageEntries));
	}, [isAuthenticating, isAuthenticated]);

	// while not authenticated, sync data to local storage on any change
	useEffect(() => {
		if (isAuthenticating || isAuthenticated) {
			return;
		}
		storeLocalEntries(allEntries);
	}, [isAuthenticating, isAuthenticated, allEntries]);

	// while authenticated, fetch data on location change
	useEffect(() => {
		if (isAuthenticating || !isAuthenticated) {
			return;
		}
		if (!isLocationEntryLoaded && !isLocationEntryLoading) {
			loadEntryMutation(locationId);
		}
	}, [isAuthenticating, isAuthenticated, locationId, isLocationEntryLoading, isLocationEntryLoaded, locationEntry]);

	/**
	 * while not authenticated, mock data load
	 */
	useEffect(() => {
		if (isAuthenticating || isAuthenticated) {
			return;
		}
		if (locationId && isLocationEntryLoaded && !locationEntryLocal) {
			navigateToLocationId(null);
		} else {
			// normally would like to invoke this only once if !locationEntryLoaded, but there's one state where locationEntryLocal is null
			dispatch(locationEntryLoaded(locationEntryLocal));
		}
	}, [isAuthenticating, isAuthenticated, isLocationEntryLoaded, locationEntryLocal, locationId]);

	useEffect(() => {
		if (locationId && loadEntryError) {
			navigateToLocationId(null);
		}
	}, [locationId, loadEntryError]);

	const isContentLoading = Boolean(!isLocationEntryLoaded);

	// TODO: decentralize this logic and the loader part as well
	const shouldRenderRepositoryView = locationId && locationEntry?.type === EntityType.Repository;
	const shouldRenderRepositoryEntryView = locationId && locationEntry?.type === EntityType.RepositoryEntry;
	const shouldRenderGenericEntryView = locationEntry && !shouldRenderRepositoryView && !shouldRenderRepositoryEntryView || !locationId && !locationEntry;
	const shouldAllowNestedEntries = shouldRenderRepositoryView || shouldRenderGenericEntryView;

	return (
		<Box display='flex' flexDirection='column' justifyContent='space-between' height='100dvh'>
			<HeaderWrapper>
				{shouldRenderRepositoryView && !isContentLoading && <RepositoryHeaderContent />}
				{shouldRenderGenericEntryView && !isContentLoading && <GenericHeaderContent />}
			</HeaderWrapper>
			<Box flex={1} display='flex' flexDirection='column'>
				{shouldRenderRepositoryView && <RepositoryView isContentLoading={isContentLoading} />}
				{shouldRenderRepositoryEntryView && <RepositoryEntryView isContentLoading={isContentLoading} />}
				{shouldRenderGenericEntryView && <GenericEntryView isContentLoading={isContentLoading} />}
				{isContentLoading && <Box flex={1} display='flex' justifyContent='center' alignItems='center'><CircularProgress /></Box>}
			</Box>
			<AddButtonPadding />
			{shouldAllowNestedEntries && <FabAreaWrapperBottomRight>
				{shouldRenderGenericEntryView && <ClipboardActionsFab />}
				<AddButton onClick={() => openAddDialog()} disabled={isContentLoading} />
			</FabAreaWrapperBottomRight>}
			<FooterNotification />
		</Box>
	);
}

export default ContentWrapper;
