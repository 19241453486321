import {createSelector} from '@reduxjs/toolkit';
import {
	EntityType,
	Entry, EntryProperty, EntryPropertyBelongsTo, PropertyDefinition, Repository,
} from '../types';
import {
	selectAllEntries,
	selectEntryReadableValues,
	selectLocationAllEntries,
} from './contentSlice';
import {
	selectRepositoryEntry
} from './repositoryViewSlice';
import {ENTRY_PROPERTY_TYPES, PROPERTY_DEFINITION_TYPES} from '../constants';
import {
	populateRepositoryEntry,
	populateSelectOptionsRepositoryEntries,
	sortEntryByRank
} from '../common/util';

export const selectPopulatedRepositoryEntry = createSelector([selectRepositoryEntry, selectAllEntries, selectEntryReadableValues], (repositoryEntry, allEntries, entryReadableValues) => {
	if (!repositoryEntry) {
		return null;
	}
	const repositoryId = repositoryEntry.parentId;
	const propertyDefinitions = allEntries.filter((entry) => PROPERTY_DEFINITION_TYPES.includes(entry.type as any) && (entry as PropertyDefinition).parentId === repositoryId) as PropertyDefinition[];
	const entryProperties = allEntries.filter((entry) => ENTRY_PROPERTY_TYPES.includes(entry.type as any) && (entry as EntryProperty).repositoryId === repositoryId) as EntryProperty[];
	const entryHasProperties = allEntries.filter((entry) => (entry as any).type === EntityType.EntryPropertyBelongsTo && (entry as EntryPropertyBelongsTo).targetRepositoryId === repositoryId) as EntryPropertyBelongsTo[];

	return {...populateRepositoryEntry(repositoryEntry, entryProperties, entryHasProperties, propertyDefinitions, entryReadableValues), propertyDefinitions };
});

export const selectEntryRepository = createSelector([selectRepositoryEntry, selectAllEntries], (repositoryEntry, allEntries): Repository | null => {
	if (!repositoryEntry) {
		return null;
	}
	const repository = allEntries.find((entry) => entry.type === EntityType.Repository && entry.id === repositoryEntry.parentId);
	return repository ? repository as Repository : null;
});

export const selectRepositoryEntryPropertyDefinitions = createSelector([selectRepositoryEntry, selectAllEntries], (repositoryEntry, allEntries) => {
	if (!repositoryEntry) {
		return [];
	}
	const repositoryId = repositoryEntry.parentId;
	return allEntries.filter((entry) => PROPERTY_DEFINITION_TYPES.includes(entry.type as any) && (entry as PropertyDefinition).parentId === repositoryId).sort(sortEntryByRank) as PropertyDefinition[];
});

export const selectCurrentSubEntries = createSelector([selectLocationAllEntries], (locationAllEntries) => locationAllEntries.filter(({type}: Entry) => !ENTRY_PROPERTY_TYPES.includes(type as any)).sort(sortEntryByRank));

export const selectCurrentLocationEntriesMaxRank = createSelector([selectCurrentSubEntries], (entries: Entry[]) => {
	return entries.reduce((acc, record) => record.rank && record.rank > acc ? record.rank : acc, 0);
});

export const selectRepositoryEntrySelectOptions = createSelector([selectRepositoryEntryPropertyDefinitions, selectAllEntries, selectEntryReadableValues], (propertyDefinitions, allEntries, entryReadableValues) => {
	return populateSelectOptionsRepositoryEntries(propertyDefinitions, allEntries, entryReadableValues);
});
