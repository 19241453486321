import React, {useCallback} from 'react';
import {
	clipboardEntriesAddById,
	entriesUpdateMany,
	selectClipboardEntries,
	clipboardEntriesResetState
} from '../containers/contentSlice';
import {useDispatch} from 'react-redux';
import {useAppSelector} from '../hooks';
import {Badge, Fab} from '@mui/material';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import {Entry} from '../types';
import {selectLocationId} from '../containers/contentSlice';
import {selectIsAuthenticated} from '../containers/authSlice';
import {useEntryUpdateMutation} from '../services/records';

export function useClipboard() {
	const dispatch = useDispatch();
	const clipboardEntries = useAppSelector(selectClipboardEntries);
	const locationId = useAppSelector(selectLocationId);
	const isAuthenticated = useAppSelector(selectIsAuthenticated);
	const [entryUpdateMutation] = useEntryUpdateMutation();

	const cutEntry = useCallback((id: string) => {
		dispatch(clipboardEntriesAddById(id));
	}, []);

	const pasteEntries = useCallback(() => {
		const updates = clipboardEntries.map(clipboardEntry => ({
			...clipboardEntry,
			parentId: locationId,
		} as Entry));
		if (isAuthenticated) {
			entryUpdateMutation(updates);
		} else {
			dispatch(entriesUpdateMany(updates));
		}
		dispatch(clipboardEntriesResetState());
	}, [locationId, clipboardEntries, clipboardEntries]);

	return {cutEntry, pasteEntries, clipboardEntries};
}

const ClipboardActionsFab = () => {
	const {clipboardEntries, pasteEntries} = useClipboard();
	return <>
		{(clipboardEntries.length > 0) && <Fab size="small" aria-label="scroll back to top" style={{marginRight: '1rem'}} onClick={() => pasteEntries()}>
			<Badge badgeContent={clipboardEntries.length} color="error">
				<ContentPasteGoIcon />
			</Badge>
		</Fab>}
	</>;};

export default ClipboardActionsFab;
