import React, {MutableRefObject} from 'react';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Menu from '@mui/material/Menu';
import {css} from '@emotion/css';
import SubdirectoryArrowLeftIcon from '@mui/icons-material/SubdirectoryArrowLeft';
import {Typography} from '@mui/material';
import {Entry} from '../types';

interface Props {
  menuEntry: Entry | null;
  menuAnchorEl: MutableRefObject<Element | null>;
  closeMenu: () => void;
	onGoToBtnClick: (id: string) => void;
  onEditBtnClick: (id: string) => void;
  onDeleteBtnClick: (id: string) => void;
}

function RepositoryEntriesListItemMenu({menuAnchorEl, menuEntry, closeMenu, onGoToBtnClick, onEditBtnClick, onDeleteBtnClick}: Props) {
	if (!menuAnchorEl) {
		return null;
	}

	return <Menu
		id="demo-positioned-menu"
		aria-labelledby="demo-positioned-button"
		anchorEl={menuAnchorEl.current}
		open={!!menuEntry}
		onClose={closeMenu}
		anchorOrigin={{
			vertical: 'top',
			horizontal: 'left',
		}}
		className={css`
				& .MuiPaper-root {
					min-width: 240px;
				}
			`}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'left',
		}}
	>
		{onGoToBtnClick ? <MenuItem onClick={() => menuEntry ? onGoToBtnClick(menuEntry.id) : null}>
			<ListItemIcon>
				<SubdirectoryArrowLeftIcon fontSize="small" />
			</ListItemIcon>
			<ListItemText>Go to</ListItemText>
			<Typography variant="body2">
				enter
			</Typography>
		</MenuItem> : null}
		<MenuItem onClick={() => menuEntry ? onEditBtnClick(menuEntry.id) : null}>
			<ListItemIcon>
				<EditIcon fontSize="small" />
			</ListItemIcon>
			<ListItemText>Edit</ListItemText>
		</MenuItem>
		<MenuItem onClick={() => menuEntry ? onDeleteBtnClick(menuEntry.id) : null}>
			<ListItemIcon>
				<DeleteForeverIcon fontSize="small" />
			</ListItemIcon>
			<ListItemText>Delete</ListItemText>
		</MenuItem>
	</Menu>;
}

export default RepositoryEntriesListItemMenu;
