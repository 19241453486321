import { Middleware } from '@reduxjs/toolkit';
import { resetState } from './containers/authSlice';
import {AUTH401_ACTION_TYPE} from './constants';

const authMiddleware: Middleware = (store) => (next) => (action) => {
	if (action.type === AUTH401_ACTION_TYPE) {
		store.dispatch(resetState()); // triggers token swap
	}
	return next(action);
};

export default authMiddleware;
