import * as React from 'react';
import {ReactNode, useCallback, useEffect, useState} from 'react';
import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {Box, DialogTitle} from '@mui/material';
import {
	EntityType,
	FormData,
	PropertyDefinitionBelongsTo,
	PropertyDefinitionHas,
	PropertyDefinitionSelectStringOptionEntry,
	Repository,
	Theme,
} from '../types';
import {
	PROPERTY_DEFINITION_TYPES,
	REPOSITORIES_BY_ENTITY_TYPE,
} from '../constants';
import FormTemplate from './FormTemplate';
import EntityTypeSelectButton from './EntityTypeSelectButton';
import {
	assignRandomId,
	initFormData,
	populateSelectOptionsRepositories
} from '../common/util';
import {entriesAddMany, selectAllEntries, selectLocationId} from '../containers/contentSlice';
import {useDispatch} from 'react-redux';
import {useAppDispatch, useAppSelector} from '../hooks';
import {useEntryCreateMutation} from '../services/records';
import {selectIsAuthenticated} from '../containers/authSlice';
import slugify from 'slugify';
import pluralize from 'pluralize';
import {
	formDataSetState,
	addFormRepositoryOptionsSetState,
	openAddForm,
	formInit,
	selectFormData,
	selectAddFormRepository,
	selectFormEntityTypeOptions,
	selectFormSelectOptions,
	selectFormTemplateProperties,
	selectIsAddDialogOpen,
	selectAddFormRepositoryPropertyDefinitions,
	selectAddFormRepositoryEntryType,
	closeAddForm,
} from '../containers/formDataSlice';
import {selectCurrentLocationEntriesMaxRank} from '../containers/repositoryEntryViewSlice';
import {NOTE_REPOSITORY} from '../systemRecords/note';
import { selectRepository } from '../containers/repositoryViewSlice';
import {REPOSITORY_ENTRY_REPOSITORY} from '../systemRecords/repositoryEntry';

interface DialogProps {
	open: boolean;
	repositoryOptions: PropertyDefinitionSelectStringOptionEntry[],
	onClose: () => void;
	onSubmit: (formData: FormData) => void;
	onRepositoryOptionChange: (type: string) => void;
	title: string;
	children: ReactNode;
}

interface Props {
	onChange: (key: string, value: any) => void;
}

const DialogHeaderButtonArea = styled.span`
  padding: ${({theme}) => (theme as Theme).spacing(0, 1)};
`;

const getAutofillValues = (entityType: EntityType, key: string, value: any): FormData => {
	if (PROPERTY_DEFINITION_TYPES.includes(entityType)) {
		if (key === 'label') {
			return {slug: slugify(value, {lower:true})};
		}
	} else if (entityType === EntityType.Repository) {
		return key === 'title' && pluralize.isPlural(value) ? {singular: pluralize.singular(value)} : {};
	}
	return {};
};

interface OpenDialogProps {
	repositoryId?: string;
	linkToProperty?: PropertyDefinitionBelongsTo | PropertyDefinitionHas;
	linkToEntryId?: string;
}

export const useAddEntryForm = () => {
	const dispatch = useDispatch();
	const parentId = useAppSelector(selectLocationId);
	const formData = useAppSelector(selectFormData);
	const isAuthenticated = useAppSelector(selectIsAuthenticated);
	const [entryCreateMutation] = useEntryCreateMutation();
	const addFormRepositoryOptions = useAppSelector(selectFormEntityTypeOptions);
	const currentMaxRank = useAppSelector(selectCurrentLocationEntriesMaxRank);
	const isAddDialogOpen = useAppSelector(selectIsAddDialogOpen);
	const allEntries = useAppSelector(selectAllEntries);
	const locationRepository = useAppSelector(selectRepository);
	const addFormRepositoryEntryType = useAppSelector(selectAddFormRepositoryEntryType);

	// TODO: this looks like a refactor is overdue
	const openAddDialog = useCallback(({repositoryId, linkToProperty, linkToEntryId}: OpenDialogProps = {}) => {
		if (repositoryId) {
			const repository = [...Object.values(REPOSITORIES_BY_ENTITY_TYPE), ...allEntries].find(({id, type}) => type === EntityType.Repository && id === repositoryId);
			if (repository) {
				dispatch(openAddForm({ repository: repository as Repository, linkToProperty, linkToEntryId }));
			}
		} else {
			const repositoryId = addFormRepositoryOptions[0]?.value;
			const repository = [...Object.values(REPOSITORIES_BY_ENTITY_TYPE), ...allEntries].find(({id, type}) => type === EntityType.Repository && id === repositoryId);
			if (repository && repository.id === REPOSITORY_ENTRY_REPOSITORY.id) {
				dispatch(openAddForm({ repository: locationRepository as Repository }));
			} else if (repository) {
				dispatch(openAddForm({ repository: repository as Repository }));
			}
		}
	}, [dispatch, addFormRepositoryOptions, allEntries]);

	const closeAddDialog = () => dispatch(closeAddForm());

	const handleAddDialogValueChange = useCallback((key: string, value: any) => {
		if (value !== ' ') {
			const autoFillData = getAutofillValues(addFormRepositoryEntryType as EntityType, key, value);
			dispatch(formDataSetState({
				...formData,
				...autoFillData,
				[key]: value,
			}));
		}
	}, [formData]);

	const onAddDialogSubmit = (payload: FormData) => {
		const newEntryPayload = assignRandomId({
			...payload,
			type: addFormRepositoryEntryType as EntityType,
			parentId: parentId ?? null,
			rank: currentMaxRank + 1
		});
		if (isAuthenticated) {
			entryCreateMutation([newEntryPayload]);
		} else {
			dispatch(entriesAddMany([newEntryPayload]));
		}
		closeAddDialog();
	};

	return {isAddDialogOpen, openAddDialog, closeAddDialog, handleAddDialogValueChange, onAddDialogSubmit};
};

export const AddEntryFormDialog: React.FC<DialogProps> = ({ open, repositoryOptions, onClose, onRepositoryOptionChange, onSubmit, title, children}) => {
	const dispatch = useAppDispatch();
	const formData = useAppSelector(selectFormData);
	const addFormRepository = useAppSelector(selectAddFormRepository);
	const formTemplateProperties = useAppSelector(selectFormTemplateProperties);
	const [dialogTitle, setDialogTitle] = useState<string>(title);

	useEffect(() => {
		dispatch(addFormRepositoryOptionsSetState(repositoryOptions));
	}, [repositoryOptions]);

	// cache content to prevent it from disappearing during dialog fade out
	useEffect(() => {
		if (open) {
			setDialogTitle(title);
		}
	}, [open, title, addFormRepository]);

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		// temporarily transform to flat structure
		const submitData = formTemplateProperties.reduce((acc, def) => {
			if (def.type === EntityType.PropertyDefinitionBelongsTo) {
				return { ...acc, [def.slug]: (formData[def.slug] as string[])[0] };
			}
			return acc;
		}, formData);
		onSubmit(submitData);
	};

	const shouldDisplayTypeSelectButton = repositoryOptions.length > 1;
	const shouldShowDialogTitle = addFormRepository && addFormRepository.id !== NOTE_REPOSITORY.id;
	const submitBtnLabel = addFormRepository && addFormRepository.id === EntityType.Note ? 'Add note' : 'Add';

	return (
		<Dialog open={open} onClose={onClose} maxWidth='xs' sx={{
			'& .MuiDialog-container': {
				'& .MuiPaper-root': {
					width: '100%',
				},
			},
		}}>
			<Box display='flex' justifyContent='space-between' alignItems='center'>
				{shouldShowDialogTitle ? <DialogTitle>{dialogTitle}</DialogTitle> : <DialogTitle>&nbsp;</DialogTitle>}
				{shouldDisplayTypeSelectButton && <DialogHeaderButtonArea>
					<EntityTypeSelectButton selectOptions={repositoryOptions} onChange={onRepositoryOptionChange} />
				</DialogHeaderButtonArea>}
			</Box>
			<form onSubmit={handleSubmit} autoComplete='off'>
				<DialogContent sx={{padding: '0 24px 20px'}}>
					{children}
				</DialogContent>
				<DialogActions>
					<Button onClick={onClose}>Discard</Button>
					<Button type='submit' variant="contained">{submitBtnLabel}</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
};

function AddEntryForm({onChange}: Props) {
	const dispatch = useAppDispatch();
	const formData = useAppSelector(selectFormData);
	const selectOptions = useAppSelector(selectFormSelectOptions);
	const formTemplateProperties = useAppSelector(selectFormTemplateProperties);
	const allEntries = useAppSelector(selectAllEntries);
	const addFormRepository = useAppSelector(selectAddFormRepository);
	const addFormRepositoryPropertyDefinitions = useAppSelector(selectAddFormRepositoryPropertyDefinitions);

	const initForm = () => {
		const formData = initFormData(addFormRepositoryPropertyDefinitions);
		const formSelectOptions = populateSelectOptionsRepositories(addFormRepositoryPropertyDefinitions, allEntries);
		dispatch(formInit({
			formTemplateProperties: addFormRepositoryPropertyDefinitions, // not necessary?
			formSelectOptions,
			formData
		}));
	};

	useEffect(() => {
		if (addFormRepository) {
			initForm();
		}
	}, [addFormRepository]);

	return <FormTemplate
		propertyDefinitions={formTemplateProperties}
		formData={formData}
		onValueChange={onChange}
		selectOptions={selectOptions}
	/>;
}

export default AddEntryForm;
