import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import {store} from './store';
import {Auth0Provider} from '@auth0/auth0-react';
import config from './common/config';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<Provider store={store}>
		<Auth0Provider
			domain={config.auth0domain}
			clientId={config.auth0ClientId}
			authorizationParams={{
				redirect_uri: window.location.origin
			}}
			cacheLocation='localstorage'
		>
			<App />
		</Auth0Provider>
	</Provider>
);
