import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import SubdirectoryArrowLeftIcon from '@mui/icons-material/SubdirectoryArrowLeft';
import ListItemText from '@mui/material/ListItemText';
import {Typography} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Menu from '@mui/material/Menu';
import {css} from '@emotion/css';
import {Entry} from '../types';
import {useEditForm} from './EditEntryForm';
import {useConfirmDeleteEntryDialog} from './ConfirmDeleteEntryDialog';
import {useClipboard} from './ClipboardActionsFab';
import useNavigateToLocationId from './NavigateToLocationId';

interface Props {
  menuEntry: Entry | null;
  menuAnchorEl: HTMLButtonElement | null;
  closeMenu: () => void;
  onEditBtnClick: (id: string) => void;
  onDeleteBtnClick: (id: string) => void;
  onGoToBtnClick?: (id: string) => void;
  onCutBtnClick?: (id: string) => void;
}

interface UseGenericListMenuEffectProps {
	entries: Entry[];
}

export function useGenericListItemMenu({ entries }: UseGenericListMenuEffectProps) {
	const {openEditDialog} = useEditForm();
	const {openConfirmDeleteDialog} = useConfirmDeleteEntryDialog();
	const {cutEntry} = useClipboard();
	const navigateToLocationId = useNavigateToLocationId();

	const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLButtonElement>(null);
	const [menuEntry, setMenuEntry] = React.useState<null | Entry>(null);

	const openListItemMenu = (entry: Entry) => setMenuEntry(entry);
	const closeListItemMenu = () => setMenuEntry(null);

	const handleMenuEditBtnClick = (id: string) => {
		closeListItemMenu();
		const entry = entries.find((entry) => entry.id === id);
		if (entry) {
			openEditDialog(entry);
		}
	};

	const handleMenuDeleteBtnClick = (id: string) => {
		closeListItemMenu();
		const entry = entries.find((entry) => entry.id === id);
		if (entry) {
			openConfirmDeleteDialog(entry as Entry);
		}
	};

	const handleMenuGoToBtnClick = (id: string) => {
		closeListItemMenu();
		navigateToLocationId(id);
	};

	const handleMenuCutBtnClick = (id: string) => {
		closeListItemMenu();
		cutEntry(id);
	};

	return {menuAnchorEl, setMenuAnchorEl, menuEntry, setMenuEntry, openListItemMenu, closeListItemMenu, handleMenuEditBtnClick, handleMenuDeleteBtnClick, handleMenuGoToBtnClick, handleMenuCutBtnClick};
}

function GenericListItemMenu({menuAnchorEl, menuEntry, closeMenu, onGoToBtnClick, onEditBtnClick, onCutBtnClick, onDeleteBtnClick}: Props) {
	if (!menuAnchorEl) {
		return null;
	}

	return <Menu
		id="demo-positioned-menu"
		aria-labelledby="demo-positioned-button"
		anchorEl={menuAnchorEl}
		open={!!menuEntry}
		onClose={closeMenu}
		anchorOrigin={{
			vertical: 'top',
			horizontal: 'left',
		}}
		className={css`
				& .MuiPaper-root {
					min-width: 240px;
				}
			`}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'left',
		}}
	>
		{onGoToBtnClick ? <MenuItem onClick={() => menuEntry ? onGoToBtnClick(menuEntry.id) : null}>
			<ListItemIcon>
				<SubdirectoryArrowLeftIcon fontSize="small" />
			</ListItemIcon>
			<ListItemText>Go to</ListItemText>
			<Typography variant="body2">
        enter
			</Typography>
		</MenuItem> : null}
		<MenuItem onClick={() => menuEntry ? onEditBtnClick(menuEntry.id) : null}>
			<ListItemIcon>
				<EditIcon fontSize="small" />
			</ListItemIcon>
			<ListItemText>Edit</ListItemText>
			<Typography variant="body2">
        spacebar
			</Typography>
		</MenuItem>
		{onCutBtnClick ? <MenuItem onClick={() => menuEntry ? onCutBtnClick(menuEntry.id) : null}>
			<ListItemIcon>
				<ContentCutIcon fontSize="small" />
			</ListItemIcon>
			<ListItemText>Cut</ListItemText>
			<Typography variant="body2">
        ⌘X
			</Typography>
		</MenuItem> : null}
		<MenuItem onClick={() => menuEntry ? onDeleteBtnClick(menuEntry.id) : null}>
			<ListItemIcon>
				<DeleteForeverIcon fontSize="small" />
			</ListItemIcon>
			<ListItemText>Delete</ListItemText>
			<Typography variant="body2">
        backspace
			</Typography>
		</MenuItem>
	</Menu>;
}

export default GenericListItemMenu;
