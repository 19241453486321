import {EntityType, Repository, PropertyDefinition} from '../types';

export const TEMPLATE_PROPERTY_BOOLEAN_REPOSITORY: Repository = {
	id: 'idPropertyDefinitionBooleanRepository',
	parentId: null,
	type: EntityType.Repository,
	title: 'Boolean properties',
	singular: 'Boolean property',
};

export const TEMPLATE_PROPERTY_BOOLEAN_TEMPLATE_PROPERTIES: PropertyDefinition[] = [{
	id: 'idPropertyDefinitionBooleanTemplateTitleProperty',
	parentId: TEMPLATE_PROPERTY_BOOLEAN_REPOSITORY.id,
	type: EntityType.PropertyDefinitionString,
	slug: 'label',
	label: 'Label',
	required: true,
	autoFocus: true,
}, {
	id: 'idPropertyDefinitionBooleanTemplateSlugProperty',
	parentId: TEMPLATE_PROPERTY_BOOLEAN_REPOSITORY.id,
	type: EntityType.PropertyDefinitionString,
	slug: 'slug',
	label: 'Key',
	required: true,
	disabled: true,
}];
