import {EntityType, Repository, PropertyDefinition} from '../types';

export const REPOSITORY_REPOSITORY: Repository = {
	id: 'idRepositoryRepository',
	parentId: null,
	type: EntityType.Repository,
	title: 'Repositories',
	singular: 'Repository',
};

export const REPOSITORY_REPOSITORY_TEMPLATE_PROPERTIES: PropertyDefinition[] = [{
	id: 'idRepositoryTemplateTitleProperty',
	parentId: REPOSITORY_REPOSITORY.id,
	type: EntityType.PropertyDefinitionString,
	slug: 'title',
	label: 'Title',
	required: true,
	autoFocus: true,
}, {
	id: 'idRepositoryTemplateSingularProperty',
	parentId: REPOSITORY_REPOSITORY.id,
	type: EntityType.PropertyDefinitionString,
	slug: 'singular',
	label: 'Singular',
	required: true,
}];
