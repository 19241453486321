import {ButtonGroup, useTheme} from '@mui/material';
import {css} from '@emotion/css';
import {RepositoryViewTab} from '../types';
import Button from '@mui/material/Button';
import TableRowsIcon from '@mui/icons-material/TableRows';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import React from 'react';
import {useAppSelector} from '../hooks';
import {selectTab, tabSetState, selectIsEntriesTabEnabled} from '../containers/repositoryViewSlice';
import {useDispatch} from 'react-redux';

export const RepositoryHeaderTabs = () => {
	const theme = useTheme();
	const dispatch = useDispatch();
	const classes = {
		activeViewTab: css`
			&.MuiButton-text {
				background-color: ${theme.palette.action.selected}
			}
		`
	};
	const viewTab = useAppSelector(selectTab);
	const isEntriesTabEnabled = useAppSelector(selectIsEntriesTabEnabled);

	const setViewTab = (tab: RepositoryViewTab) => {
		dispatch(tabSetState(tab));
	};

	return <ButtonGroup
		disableElevation
		variant="text"
		aria-label="Disabled button group"
	>
		<Button disabled={!isEntriesTabEnabled} className={viewTab === RepositoryViewTab.Entries ? classes.activeViewTab : ''} onClick={() => setViewTab(RepositoryViewTab.Entries)}><TableRowsIcon /></Button>
		<Button className={viewTab === RepositoryViewTab.Template ? classes.activeViewTab : ''} onClick={() => setViewTab(RepositoryViewTab.Template)}><DashboardCustomizeIcon /></Button>
	</ButtonGroup>;
};