import * as React from 'react';
import {MutableRefObject, useEffect, useRef} from 'react';
import TextField from '@mui/material/TextField';
import {
	EntityType,
	FormData,
	FormSelectOptions,
	PropertyDefinition,
	PropertyDefinitionBelongsTo,
	PropertyDefinitionStringEntry,
	SelectOption,
} from '../types';
import {
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	InputLabel,
	ListItemText,
	MenuItem,
	Select,
	SelectChangeEvent,
	useTheme,
} from '@mui/material';
import {css} from '@emotion/css';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

interface SelectFieldProps {
  slug: string,
  label: string,
	selectOptions: SelectOption[],
  value: string,
	required?: boolean,
	onValueChange: (key: string, value: any) => void;
}

interface SelectMultipleFieldProps {
	slug: string,
	label: string,
	selectOptions: SelectOption[],
	values: string[],
	onValueChange: (key: string, value: any) => void;
	required?: boolean,
	disabled?: boolean,
}

type SyntheticEventTarget = EventTarget & { checked: boolean };

const SelectField = ({slug, label, selectOptions, onValueChange, value, required }: SelectFieldProps) => {
	const handleSelectChange = (e: SelectChangeEvent<string>) => onValueChange(slug, [e.target.value]);
	return <div>
		<FormControl fullWidth variant="standard" margin="dense" required={required}>
			<InputLabel id="demo-simple-select-label">{label}</InputLabel>
			<Select
				labelId="demo-simple-select-label"
				value={value ?? ''}
				onChange={handleSelectChange}
			>
				{selectOptions.map((selectOption) => <MenuItem key={selectOption.value} value={selectOption.value ?? ''}>{selectOption.label}</MenuItem> )}
				{!required && (<MenuItem value=''>Unassigned</MenuItem>)}
			</Select>
		</FormControl>
	</div>;
};

const SelectMultipleField = ({slug, label, selectOptions, onValueChange, values, required, disabled }: SelectMultipleFieldProps) => {
	const handleChange = (event: SelectChangeEvent<any>) => {
		onValueChange(slug, event.target.value);
	};

	const optionLabelsMap = selectOptions.reduce((acc, option) => acc.set(option.value, option.label), new Map<string, string>());

	return <div>
		<FormControl fullWidth variant="standard" margin="dense" required={required} disabled={disabled}>
			<InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel>
			<Select
				labelId="demo-multiple-checkbox-label"
				id="demo-multiple-checkbox"
				multiple
				value={values}
				onChange={handleChange}
				renderValue={(selected) => selected.map((val) => optionLabelsMap.get(val)).join(', ')}
				MenuProps={MenuProps}
			>
				{selectOptions.map((selectOption: any) => (
					<MenuItem key={selectOption.value} value={selectOption.value}>
						<Checkbox checked={values.indexOf(selectOption.value) > -1} />
						<ListItemText primary={selectOption.label} />
					</MenuItem>
				))}
			</Select>
		</FormControl>
	</div>;
};

interface TemplateFieldProps {
	def: PropertyDefinition;
	value: any;
	onValueChange: (key: string, value: any) => void;
	autoFocusRef: MutableRefObject<HTMLInputElement | undefined>
	selectOptions: SelectOption[];
	displayRelationsAsTables?: boolean;
	onSelectOptionCreateClick?: (def: PropertyDefinitionBelongsTo) => void;
	readOnly?: boolean;
}

const FormTemplateField = (props: TemplateFieldProps) => {
	const {def, value, onValueChange, autoFocusRef, readOnly} = props;
	const {type, slug, label, required} = def;
	const theme = useTheme();
	const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => onValueChange(e.target.id, e.target.value);
	const handleBooleanChange = (e: React.SyntheticEvent) => onValueChange(slug, (e.target as SyntheticEventTarget).checked);

	const classes = {
		booleanField: css`
			margin-top: ${theme.spacing(2)};
			user-select: none;
		`
	};

	switch (type) {
	case EntityType.PropertyDefinitionString: {
		const {disabled: propertyDisabled, autoFocus, placeholder} = def as PropertyDefinitionStringEntry;
		const disabled = Boolean(propertyDisabled ?? readOnly);
		return <TextField
			key={slug}
			required={required}
			margin="dense"
			id={slug}
			label={label}
			type="text"
			fullWidth
			variant="standard"
			value={value}
			onChange={handleTextChange}
			disabled={disabled}
			placeholder={placeholder}
			inputProps={autoFocus ? {
				ref: autoFocusRef,
			} : undefined}
			autoComplete='off'
		/>;
	}
	case EntityType.PropertyDefinitionText:
		return <TextField
			key={slug}
			required={required}
			multiline
			margin="dense"
			id={slug}
			label={label}
			type="text"
			fullWidth
			variant="standard"
			value={value}
			onChange={handleTextChange}
			autoComplete='off'
		/>;
	case EntityType.PropertyDefinitionBoolean: {
		const disabled = Boolean(readOnly);
		return <FormControlLabel
			key={slug}
			control={<Checkbox checked={value as boolean}/>}
			label={label}
			onChange={handleBooleanChange}
			className={classes.booleanField}
			disabled={disabled}
		/>;
	}
	case EntityType.PropertyDefinitionBelongsTo: {
		const {selectOptions, displayRelationsAsTables, onSelectOptionCreateClick} = props;
		const disabled = Boolean(readOnly);
		if ((def as PropertyDefinitionBelongsTo).allowMany) {
			return <SelectMultipleField
				key={slug}
				slug={slug}
				label={label}
				selectOptions={selectOptions}
				onValueChange={onValueChange}
				values={value as string[]}
				required={required}
				disabled={disabled}
			/>;
		} else {
			return <SelectField
				key={slug}
				slug={slug}
				label={label}
				selectOptions={selectOptions}
				onValueChange={onValueChange}
				value={(value as string[])[0] ?? null}
				required={required}
			/>;
		}
	}
	default:
		return null;
	}
};

interface Props {
	formData: FormData;
	propertyDefinitions: PropertyDefinition[];
	onValueChange?: (key: string, value: any) => void;
	readOnly?: boolean;
	selectOptions?: FormSelectOptions;
	displayRelationsAsTables?: boolean;
	onSelectOptionCreateClick?: (def: PropertyDefinitionBelongsTo) => void;
}

function FormTemplate({ propertyDefinitions, formData, onValueChange, selectOptions, readOnly, displayRelationsAsTables, onSelectOptionCreateClick }: Props) {
	const autoFocusRef = useRef<HTMLInputElement>();

	useEffect(() => {
		if (propertyDefinitions.length) {
			setTimeout(() => {
				if (autoFocusRef.current) {
					(autoFocusRef.current as HTMLInputElement).focus();
				}
			}, 100);
		}
	}, [autoFocusRef.current, propertyDefinitions]);

	return <FormGroup>{propertyDefinitions.map((def) => <FormTemplateField
		key={def.id}
		autoFocusRef={autoFocusRef}
		def={def}
		value={formData[def.slug]}
		selectOptions={selectOptions ? selectOptions[def.slug] : []}
		readOnly={readOnly}
		onValueChange={onValueChange ? onValueChange : () => {return;}}
		displayRelationsAsTables={displayRelationsAsTables}
		onSelectOptionCreateClick={onSelectOptionCreateClick}
	/>)}</FormGroup>;
}

export default FormTemplate;