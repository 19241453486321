import React, {useEffect, useState} from 'react';
import {useAppSelector} from '../hooks';
import {css} from '@emotion/css';
import {Box, useTheme} from '@mui/material';
import Button from '@mui/material/Button';
import {entryGetReadableValue} from '../common/util';
import {
	selectEntryReadableValues, selectIsLocationEntryLoaded,
	selectLocationEntry
} from '../containers/contentSlice';
import {Entry} from '../types';

function GenericHeaderContent() {
	const entry = useAppSelector(selectLocationEntry);
	const isLocationEntryLoaded = useAppSelector(selectIsLocationEntryLoaded);
	const entryReadableValues = useAppSelector(selectEntryReadableValues);
	const [cachedEntry, setCachedEntry] = useState<Entry | null>(null);

	useEffect(() => {
		if (isLocationEntryLoaded) {
			setCachedEntry(entry);
		}
	}, [entry, isLocationEntryLoaded]);

	const theme = useTheme();

	const classes = {
		titleLink: css`
		white-space: nowrap;
		overflow: hidden;
		text-align: left;
	`
	};

	let title = '';
	if (cachedEntry) {
		title = entryGetReadableValue(cachedEntry, entryReadableValues);
	}

	return cachedEntry ? <Box className={classes.titleLink}><Button disabled style={{color: theme.palette.text.primary}}>
		{title}
	</Button></Box> : null;
}

export default GenericHeaderContent;
