import React, {ReactNode} from 'react';
import {
	TableBody,
	TableContainer,
	TableRow,
	TableCell,
	Table,
	Box, IconButton
} from '@mui/material';
import {Link} from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';

interface EntriesListRow {
	id: string;
	label: string;
}

interface EntriesListItemProps {
	row: EntriesListRow;
	onRowClick?: () => void;
	setMenuAnchorEl?: any;
	onMenuBtnClick?: () => void;
	selectedEntryId?: string | null;
	onEntryFocus?: (id: string) => void;
	onEntryBlur?: () => void;
	disabled?: boolean;
}

interface Props {
	children: ReactNode;
}

export const EntriesListItem = ({ row, onRowClick, setMenuAnchorEl, onMenuBtnClick, selectedEntryId, onEntryFocus, onEntryBlur, disabled }: EntriesListItemProps) => {
	const handleClick = (e: React.MouseEvent) => {
		e.preventDefault();
		if (onRowClick) {
			onRowClick();
		}
	};

	return <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 }, userSelect: 'none'}}>
		<TableCell sx={{p: (theme) => theme.spacing(1,2), height: 58}}>
			<Box display='flex' alignItems='center' sx={disabled ? {color: 'action.disabled'} : undefined}>
				{onRowClick ? <Link
					to={`/${row.id}`}
					onClick={handleClick}
					onFocus={onEntryFocus && (() => onEntryFocus(row.id))}
					onBlur={onEntryBlur && (() => onEntryBlur())}>
					{row.label}
				</Link> : <Box flex={1}>
					{row.label}
				</Box>}
				{setMenuAnchorEl && selectedEntryId === row.id && (
					<IconButton ref={setMenuAnchorEl} aria-label="menu" color="inherit" size="medium" onClick={onMenuBtnClick} style={{marginLeft:'0.5rem'}}>
						<MoreVertIcon />
					</IconButton>
				)}
			</Box>
		</TableCell>
	</TableRow>;
};

const EntriesList: React.FC<Props> = ({children }) => {
	return <TableContainer>
		<Table>
			<TableBody>
				{children}
			</TableBody>
		</Table>
	</TableContainer>;
};

export default EntriesList;