import React, {useEffect, useState} from 'react';
import {
	Box,
	Checkbox,
	Drawer,
	Hidden,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Typography
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import {EntityType, PropertyDefinitionBelongsTo, PropertyDefinitionHas} from '../types';

interface ListState {
	values: string[];
	hasChanged: boolean;
}

interface CheckboxListProps {
	rows: {id: string, label: string, checked: boolean}[];
	values: Set<string>;
	onRowToggle: ({id}: {id: string, label: string}) => void;
}

interface Props {
  def: PropertyDefinitionBelongsTo | PropertyDefinitionHas | null;
  rows: {id: string, label: string, checked: boolean}[];
  values: {value: string}[];
  onClose: () => void;
  onSubmit: (values: string[]) => void;
  onAddBtnClick: (def: PropertyDefinitionBelongsTo | PropertyDefinitionHas) => void;
}

const CheckboxList = ({rows, values, onRowToggle}: CheckboxListProps) => {
	return <List sx={{flex:1}}>
		{rows.map((row) => {
			const isChecked = values.has(row.id);
			return <ListItem key={row.id} disablePadding>
				<ListItemButton onClick={() => onRowToggle(row)} selected={isChecked}>
					<Checkbox checked={isChecked} />
					<ListItemText primary={row.label} />
				</ListItemButton>
			</ListItem>;
		})}
	</List>;
};

const SelectEntriesDrawer = ({def, onClose, onSubmit, onAddBtnClick, rows, values: initialValues}: Props) => {
	const [values, setValues] = useState(new Set<string>());
	const [hasChanged, setHasChanged] = useState(false);

	const initCheckboxListValues = () => {
		if (!initialValues) {
			return new Set<string>();
		}
		return (initialValues as {value: string}[]).reduce((acc: Set<string>, {value}: {value: string}) => acc.add(value), new Set<string>());
	};

	const handleToggle = ({id}: {id: string, label: string}) => {
		const newSet = new Set(values);
		if (values.has(id)) {
			newSet.delete(id);
		} else {
			newSet.add(id);
		}
		setValues(newSet);
		setHasChanged(true);
	};

	const handleSubmit = () => {
		if (hasChanged) {
			onSubmit(Array.from(values));
		} else {
			onClose();
		}
	};

	useEffect(() => {
		if (def) {
			const values = initCheckboxListValues();
			setValues(values);
			setHasChanged(false);
		}
	}, [def]);

	return <Drawer open={Boolean(def)} onClose={handleSubmit} anchor='right'>
		<Box display='flex' flexDirection='column' sx={{height:'100%'}}>
			{def && <List>
				<ListItem>
					<Box flex={1} display='flex' alignItems='center'>
						<Box flex={1}>
							<Typography>
                Pick {def.label}
							</Typography>
						</Box>
						<Box>
							<Hidden smUp>
								<IconButton onClick={handleSubmit}>
									<CheckIcon />
								</IconButton>
							</Hidden>
						</Box>
					</Box>
				</ListItem>
			</List>}
			<Box sx={{ flexGrow: 1, overflow: 'auto' }}>
				<CheckboxList
					rows={rows}
					values={values}
					onRowToggle={(row) => handleToggle(row)}
				/>
			</Box>
			{(def?.type === EntityType.PropertyDefinitionBelongsTo || def?.type === EntityType.PropertyDefinitionHas) && <List>
				<ListItem disablePadding>
					<ListItemButton onClick={() => onAddBtnClick(def!)}>
						<ListItemIcon>
							<AddIcon />
						</ListItemIcon>
						<ListItemText primary='Add' />
					</ListItemButton>
				</ListItem>
			</List> }
		</Box>
	</Drawer>;
};

export default SelectEntriesDrawer;
