import {Entry, ThemeMode} from './types';

const ENTRIES_STORAGE_KEY='entries';
const THEME_MODE_KEY='themeMode';

export const storage = window.localStorage;

export const storeLocalEntries = (entries: Entry[]) => {
	console.debug('updating local storage', ENTRIES_STORAGE_KEY);
	storage.setItem(ENTRIES_STORAGE_KEY, JSON.stringify(entries));
};

export const obtainLocalEntries = () => {
	const serializedEntries = storage.getItem(ENTRIES_STORAGE_KEY);
	return serializedEntries ? JSON.parse(serializedEntries) : [];
};

export const localStorageSetThemeMode = (mode: ThemeMode) => {
	console.debug('updating local storage', THEME_MODE_KEY);
	storage.setItem(THEME_MODE_KEY, mode);
};

export const localStorageGetThemeMode = (): ThemeMode | null => {
	const storedThemeMode = storage.getItem(THEME_MODE_KEY);
	return [ThemeMode.Light, ThemeMode.Dark].includes(storedThemeMode as ThemeMode) ? (storedThemeMode as ThemeMode) : null;
};
