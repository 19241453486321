import {Theme, ThemeMode} from './types';
import {createTheme} from '@mui/material/styles';
import React, {createContext, ReactNode, useContext, useEffect, useState} from 'react';
import {ThemeProvider as EmotionThemeProvider} from '@emotion/react';

const emptyFn = () => {
	return;
};

const initialState = {
	isDarkModeOn: false,
	theme: ThemeMode.Light,
	toggleDarkMode: emptyFn,
};

const ThemeContext = createContext(initialState);

export function ThemeProvider({ children }: {children: ReactNode}) {
	const [dark, setDark] = useState(false); // Default theme is light

	// On mount, read the preferred theme from the persistence
	useEffect(() => {
		const isDark = localStorage.getItem('dark') === 'true';
		setDark(isDark);
	}, [dark]);

	// To toggle between dark and light modes
	const toggle = () => {
		const isDark = !dark;
		localStorage.setItem('dark', JSON.stringify(isDark));
		setDark(isDark);
	};

	const theme = dark ? ThemeMode.Dark : ThemeMode.Light;

	return (
		<ThemeContext.Provider value={{ theme, toggleDarkMode: toggle, isDarkModeOn: dark }}>
			<EmotionThemeProvider theme={themes[theme]}>
				{children}
			</EmotionThemeProvider>
		</ThemeContext.Provider>
	);
}

export const useThemeContext = () => useContext(ThemeContext);

const lightTheme: Theme = createTheme({
	palette: {
		mode: ThemeMode.Light,
		primary: {
			main: '#3A3042', // dark purple
		},
		background: {
			default: '#f5f5f5',
			paper: '#fff'
		},
	},
});

const darkTheme: Theme = createTheme({
	palette: {
		mode: ThemeMode.Dark,
		primary: {
			main: '#D0D1AC', // sage
		},
		background: {
			default: 'rgb(34, 20, 34)', // even darker purple
			paper: 'rgb(34, 20, 34)', // even darker purple
		}
	}
});

export const themes: Record<ThemeMode, Theme> = {
	[ThemeMode.Light]: lightTheme,
	[ThemeMode.Dark]: darkTheme,
};
