import React, {useEffect, useRef, useState} from 'react';
import {Box} from '@mui/material';
import useAuth from './Auth';

const APPEAR_TIMEOUT = 2000;

const FooterNotification = () => {
	const {isAuthenticated, isAuthenticating} = useAuth();
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const timeoutRef = useRef<NodeJS.Timeout | null>(null);

	useEffect(() => {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}
		if (!isAuthenticating && !isAuthenticated) {
			timeoutRef.current = setTimeout(() => {
				setIsOpen(true);
			}, APPEAR_TIMEOUT);
		}
	}, [isAuthenticated, isAuthenticating]);

	return isOpen ? <Box sx={{backgroundImage: 'linear-gradient(90deg, #ffda02 0%, #fd5e02 100%)', pl: 2, color: 'common.black'}}>Local mode</Box> : null;
};

export default FooterNotification;
