import React, {useCallback, useEffect, useState} from 'react';
import {AppBar, Box, Toolbar, useTheme} from '@mui/material';
import {css} from '@emotion/css';
import AccountMenu from './AccountMenu';
import {Entry} from '../types';
import {useAppSelector} from '../hooks';
import {selectIsLocationEntryLoaded, selectLocationEntry} from '../containers/contentSlice';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {selectIsNavigateOutwardInitiated} from '../containers/navigationSlice';
import useNavigateToLocationId from './NavigateToLocationId';

interface Props {
  children: any;
}

function HeaderWrapper({children}: Props) {
	const theme = useTheme();
	const navigateToLocationId = useNavigateToLocationId();
	const [cachedLocationEntry, setCachedLocationEntry] = useState<Entry | null>(null);
	const isLocationEntryLoaded = useAppSelector(selectIsLocationEntryLoaded);
	const locationEntry = useAppSelector(selectLocationEntry);

	const classes = {
		menu: css`
		& > div {
		}
		& > div > button.MuiButton-text {
		  padding: ${theme.spacing(1, 2)};
		  border-radius: 0;
		}
		`
	};

	useEffect(() => {
		if (isLocationEntryLoaded) {
			setCachedLocationEntry(locationEntry);
		}
	}, [locationEntry, isLocationEntryLoaded]);

	const onLinkClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		navigateToLocationId(locationEntry?.parentId ?? null);
	}, [locationEntry]);

	const shouldShowBackBtn = Boolean(cachedLocationEntry);
	const isNavigateOutwardInitiated = useAppSelector(selectIsNavigateOutwardInitiated);

	return <>
		<AppBar position="fixed" color='transparent' style={{backgroundImage: 'none', boxShadow: 'none'}} sx={{backgroundColor: 'background.default', opacity: 0.9}}>
			<Toolbar>
				<Box display='flex' justifyContent='end' alignItems='center' sx={{width:'100%'}} className={classes.menu}>
					<Box>
						{shouldShowBackBtn && (<IconButton
							size='large'
							style={isNavigateOutwardInitiated ? {backgroundColor:theme.palette.action.focus} : {}}
							onClick={(e) => onLinkClick(e)}
							disabled={!isLocationEntryLoaded}
						>
							<ArrowBackIcon fontSize='small'/>
						</IconButton>)}
					</Box>
					{cachedLocationEntry ? children : null}
					<Box flex={1} textAlign='right'><AccountMenu /></Box>
				</Box>
			</Toolbar>
		</AppBar>
		<Toolbar />
	</>;
}

export default HeaderWrapper;
