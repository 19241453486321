import {api} from './api';
import {Entry} from '../types';
import {
	entriesAddMany,
	entriesUpdateMany,
	entriesDeleteMany,
	locationEntryLoading,
} from '../containers/contentSlice';

export const recordsApi = api.injectEndpoints({
	endpoints: (builder) => ({
		loadEntry: builder.mutation({
			query: (id) => ({
				url: id ? `records/${id}` : 'records',
				method: 'GET',
			}),
			async onQueryStarted(arg: string, { dispatch }: any) {
				dispatch(locationEntryLoading());
			},
		}),
		entryCreate: builder.mutation({
			query: (entries: Entry[]) => ({
				url: 'records',
				method: 'POST',
				body: {
					records: entries
				},
			}) as any,
			async onQueryStarted(
				arg: Entry[],
				{
					dispatch,
				}: any
			) {
				dispatch(entriesAddMany(arg));
			},
		} as any),
		entryUpdate: builder.mutation({
			query: (entries: Partial<Entry>[]) => ({
				url: 'records',
				method: 'PUT',
				body: {
					records: entries
				},
			}),
			onQueryStarted(entries: Partial<Entry>[], { dispatch }: any): Promise<void> | void {
				dispatch(entriesUpdateMany(entries));
			}
		}),
		entryDelete: builder.mutation({
			query: (id: string) => ({
				url: `records/${id}`,
				method: 'DELETE',
			}),
			onQueryStarted(arg: string, { dispatch }: any): Promise<void> | void {
				dispatch(entriesDeleteMany([arg]));
			}
		}),
	}),
});

export const { useLoadEntryMutation, useEntryCreateMutation, useEntryUpdateMutation, useEntryDeleteMutation } = recordsApi;
