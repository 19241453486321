import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
	EntityType, Entry,
	EntryProperty,
	EntryPropertyBelongsTo,
	PropertyDefinition,
	RepositoryEntry,
	RepositoryViewTab
} from '../types';
import {RootState} from '../store';
import {
	selectAllEntries,
	selectContentState,
	selectEntryReadableValues,
} from './contentSlice';
import {ENTRY_PROPERTY_TYPES, PROPERTY_DEFINITION_TYPES} from '../constants';
import {populateEntryData, populateRepositoryEntry, sortEntryByRank} from '../common/util';

interface RepositoryViewState {
  tab: RepositoryViewTab;
}

const initialState: RepositoryViewState = {
	tab: RepositoryViewTab.Entries,
};

const repositoryViewSlice = createSlice({
	name: 'repositoryView',
	initialState,
	reducers: {
		tabSetState(state: RepositoryViewState, action: PayloadAction<RepositoryViewTab>) {
			state.tab = action.payload;
		},
		resetState(state: RepositoryViewState, action: PayloadAction<void>) {
			return initialState;
		}
	}
});

export const {
	tabSetState,
	resetState,
} = repositoryViewSlice.actions;

const selectRepositoryViewState = (state: RootState) => state.repositoryView;

export const selectTab = createSelector([selectRepositoryViewState],(state: RepositoryViewState) => state.tab);

export const selectRepository = createSelector([selectContentState], (state) => state.repository);

export const selectIsRepositoryLoading = createSelector([selectContentState], (state) => state.isRepositoryLoading);

export const selectRepositoryEntry = createSelector([selectContentState], (state) => state.repositoryEntry);

export const selectIsRepositoryEntryLoading = createSelector([selectContentState], (state) => state.isRepositoryEntryLoading);

export const selectRepositoryPropertyDefinitions = createSelector([selectRepository, selectAllEntries], (repository, allEntries) => {
	if (!repository) {
		return [];
	}
	const [firstPropertyDef, ...restDefs] = allEntries
		.filter((entry) => PROPERTY_DEFINITION_TYPES.includes(entry.type) && entry.parentId === repository.id)
		.sort(sortEntryByRank);
	return (firstPropertyDef ? [{...firstPropertyDef, autoFocus: true}, ...restDefs] : []) as PropertyDefinition[];
});

export const selectIsEntriesTabEnabled = createSelector([selectRepositoryPropertyDefinitions], (repositoryPropertyDefinitions) => Boolean(repositoryPropertyDefinitions.length));

export const selectRepositoryPropertyDefinitionsPopulated = createSelector([selectRepositoryPropertyDefinitions], (propertyDefinitions) => {
	return propertyDefinitions.map((def) => populateEntryData(def as Entry));
});

export const selectRepositoryEntries = createSelector([selectRepository, selectAllEntries], (repository, allEntries) => {
	if (!repository) {
		return [];
	}
	return allEntries.filter((entry) => entry.type === EntityType.RepositoryEntry && entry.repositoryId === repository.id) as RepositoryEntry[];
});

export const selectRepositoryEntriesPopulated = createSelector([selectRepository, selectRepositoryEntries, selectAllEntries, selectEntryReadableValues], (repository, currentRepositoryEntries, allEntries, idProps) => {
	if (!repository) {
		return [];
	}
	const repositoryId = repository.id;
	const propertyDefinitions = allEntries.filter((entry) => PROPERTY_DEFINITION_TYPES.includes(entry.type as any) && (entry as PropertyDefinition).parentId === repositoryId) as PropertyDefinition[];
	const entryProperties = allEntries.filter((entry) => ENTRY_PROPERTY_TYPES.includes(entry.type as any) && (entry as EntryProperty).repositoryId === repositoryId) as EntryProperty[];
	const entryHasProperties = allEntries.filter((entry) => (entry as any).type === EntityType.EntryPropertyBelongsTo && (entry as EntryPropertyBelongsTo).targetRepositoryId === repositoryId) as EntryPropertyBelongsTo[];

	return currentRepositoryEntries
		.map((repositoryEntry) => populateRepositoryEntry(repositoryEntry, entryProperties, entryHasProperties, propertyDefinitions, idProps))
		.sort(sortEntryByRank);
});

export default repositoryViewSlice.reducer;
