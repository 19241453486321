import React, {ReactNode} from 'react';
import {Box, Container} from '@mui/material';

interface Props {
	children: ReactNode;
}

const FabAreaWrapperBottomRight: React.FC<Props> = ({ children }) => <Box
	role="presentation"
	sx={{ position: 'fixed', bottom: 40, right: 0 }}
>
	<Container maxWidth={false}>
		{children}
	</Container>
</Box>;

export default FabAreaWrapperBottomRight;
