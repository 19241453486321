import {useNavigate} from 'react-router-dom';

const useNavigateToLocationId = () => {
	const navigate = useNavigate();
	const navigateToLocationId = (id: string | null) => {
		navigate(id ? `/${id}` : '/');
	};
	return navigateToLocationId;
};

export default useNavigateToLocationId;
