import {EntityType, Repository, PropertyDefinition} from '../types';

export const TEMPLATE_PROPERTY_HAS_REPOSITORY: Repository = {
	id: 'idPropertyDefinitionHasRepository',
	parentId: null,
	type: EntityType.Repository,
	title: 'Relations (Has)',
	singular: 'Relation (Has)',
};

export const TEMPLATE_PROPERTY_HAS_TEMPLATE_PROPERTIES: PropertyDefinition[] = [{
	id: 'idPropertyDefinitionHasLabelProperty',
	parentId: TEMPLATE_PROPERTY_HAS_REPOSITORY.id,
	type: EntityType.PropertyDefinitionString,
	slug: 'label',
	label: 'Label',
	required: true,
	autoFocus: true,
}, {
	id: 'idPropertyDefinitionHasSlugProperty',
	parentId: TEMPLATE_PROPERTY_HAS_REPOSITORY.id,
	type: EntityType.PropertyDefinitionString,
	slug: 'slug',
	label: 'Key',
	required: true,
	disabled: true,
}, {
	id: 'idPropertyDefinitionHasTargetRepositoryIdProperty',
	parentId: TEMPLATE_PROPERTY_HAS_REPOSITORY.id,
	type: EntityType.PropertyDefinitionBelongsTo,
	slug: 'targetRepositoryId',
	label: 'Other repository',
	required: true,
}, {
	id: 'idPropertyDefinitionHasTargetPropertySlugProperty',
	parentId: TEMPLATE_PROPERTY_HAS_REPOSITORY.id,
	type: EntityType.PropertyDefinitionString, // TODO: change that to select
	slug: 'targetPropertySlug',
	label: 'Via property',
	required: true,
}];
