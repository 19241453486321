import React, {useCallback} from 'react';
import {MenuItem, Menu, IconButton} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
	EntityType, PropertyDefinitionSelectStringOptionEntry,
} from '../types';

interface Props {
	selectOptions: PropertyDefinitionSelectStringOptionEntry[],
	onChange: (type: EntityType) => void;
}

export default function EntityTypeSelectButton({ onChange, selectOptions }: Props) {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleChange = useCallback((value: EntityType) => {
		onChange(value);
		setAnchorEl(null);
	}, []);

	const handleClose = useCallback(() => {
		setAnchorEl(null);
	}, []);

	return (
		<div>
			<IconButton
				id="header-button"
				aria-controls={open ? 'header-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}
				tabIndex={-1}
			>
				<ArrowDropDownIcon />
			</IconButton>
			<Menu
				id="header-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'header-button',
				}}
			>
				{selectOptions.map(({id, value, label}) => {
					return <MenuItem key={id} onClick={() => handleChange(value as EntityType)}>{label}</MenuItem>;
				})}
			</Menu>
		</div>
	);
}