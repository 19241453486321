import React from 'react';
import {Fab} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const PADDING_HEIGHT = 128;

interface Props {
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
}

const AddButton = ({ onClick, disabled }: Props) => <Fab size="medium" onClick={onClick} color='primary' disabled={disabled}>
	<AddIcon />
</Fab>;

export const AddButtonPadding = () => <div>
	<div style={{height: PADDING_HEIGHT}} />
</div>;

export default AddButton;
