import {api} from './api';
import {SWAP_TOKEN_URL_PATH} from '../constants';

export interface UserInfo {
	id: string;
}

interface SwapTokenRequest {
	auth0Token: string;
}

interface SwapTokenResponse {
	token: string;
}

export const accountsApi = api.injectEndpoints({
	endpoints: (builder) => ({
		swapToken: builder.mutation<SwapTokenResponse, SwapTokenRequest>({
			query: ({ auth0Token }) => ({
				url: SWAP_TOKEN_URL_PATH,
				method: 'POST',
				body: {
					auth0Token,
				},
			}),
			extraOptions: { maxRetries: 1 }
		}),
		getUserInfo: builder.mutation<UserInfo, void>({
			query: () => ({
				url: 'accounts/userInfo',
				method: 'GET',
			}),
			extraOptions: { maxRetries: 1 }
		},
		),
	}),
});

export const { useSwapTokenMutation, useGetUserInfoMutation } = accountsApi;
