import {
	AnyEntryPropertyEntityType,
	AnyPropertyDefinitionEntityType,
	EntityType,
	Repository,
	PropertyDefinition,
	PropertyDefinitionSelectStringOptionEntry
} from './types';
import {NOTE_REPOSITORY, NOTE_REPOSITORY_TEMPLATE_PROPERTIES} from './systemRecords/note';
import {REPOSITORY_REPOSITORY, REPOSITORY_REPOSITORY_TEMPLATE_PROPERTIES} from './systemRecords/repository';
import {
	TEMPLATE_PROPERTY_STRING_REPOSITORY,
	TEMPLATE_PROPERTY_STRING_TEMPLATE_PROPERTIES
} from './systemRecords/propertyDefinitionString';
import {REPOSITORY_ENTRY_REPOSITORY} from './systemRecords/repositoryEntry';
import {
	TEMPLATE_PROPERTY_BOOLEAN_REPOSITORY,
	TEMPLATE_PROPERTY_BOOLEAN_TEMPLATE_PROPERTIES
} from './systemRecords/propertyDefinitionBoolean';
import {
	TEMPLATE_PROPERTY_BELONGS_TO_REPOSITORY,
	TEMPLATE_PROPERTY_BELONGS_TO_TEMPLATE_PROPERTIES
} from './systemRecords/propertyDefinitionBelongsTo';
import {
	TEMPLATE_PROPERTY_HAS_REPOSITORY,
	TEMPLATE_PROPERTY_HAS_TEMPLATE_PROPERTIES
} from './systemRecords/propertyDefinitionHas';

export const SYSTEM_TEMPLATE_PROPERTIES: PropertyDefinition[] = [
	...NOTE_REPOSITORY_TEMPLATE_PROPERTIES,
	...REPOSITORY_REPOSITORY_TEMPLATE_PROPERTIES,
	...TEMPLATE_PROPERTY_STRING_TEMPLATE_PROPERTIES,
	...TEMPLATE_PROPERTY_BOOLEAN_TEMPLATE_PROPERTIES,
	...TEMPLATE_PROPERTY_BELONGS_TO_TEMPLATE_PROPERTIES,
	...TEMPLATE_PROPERTY_HAS_TEMPLATE_PROPERTIES
];

export const REPOSITORIES_BY_ENTITY_TYPE: Record<string, Repository> = {
	[EntityType.Note]: NOTE_REPOSITORY,
	[EntityType.Repository]: REPOSITORY_REPOSITORY,
	[EntityType.PropertyDefinitionString]: TEMPLATE_PROPERTY_STRING_REPOSITORY,
	[EntityType.PropertyDefinitionBoolean]: TEMPLATE_PROPERTY_BOOLEAN_REPOSITORY,
	[EntityType.PropertyDefinitionBelongsTo]: TEMPLATE_PROPERTY_BELONGS_TO_REPOSITORY,
	[EntityType.PropertyDefinitionHas]: TEMPLATE_PROPERTY_HAS_REPOSITORY,
	[EntityType.RepositoryEntry]: REPOSITORY_ENTRY_REPOSITORY,
};

export const REPOSITORY_ENTRY_ENTITY_TYPES_BY_SYSTEM_REPOSITORY_IDS = {
	[NOTE_REPOSITORY.id]: EntityType.Note,
	[REPOSITORY_REPOSITORY.id]: EntityType.Repository,
	[TEMPLATE_PROPERTY_STRING_REPOSITORY.id]: EntityType.PropertyDefinitionString,
	[TEMPLATE_PROPERTY_BOOLEAN_REPOSITORY.id]: EntityType.PropertyDefinitionBoolean,
	[TEMPLATE_PROPERTY_BELONGS_TO_REPOSITORY.id]: EntityType.PropertyDefinitionBelongsTo,
	[TEMPLATE_PROPERTY_HAS_REPOSITORY.id]: EntityType.PropertyDefinitionHas,
};

export const PROPERTY_DEFINITION_TYPES = [
	EntityType.PropertyDefinitionString,
	EntityType.PropertyDefinitionBoolean,
	EntityType.PropertyDefinitionBelongsTo,
	EntityType.PropertyDefinitionHas,
];

export const ENTRY_PROPERTY_TYPES = [
	EntityType.EntryPropertyString,
	EntityType.EntryPropertyBoolean,
	EntityType.EntryPropertyBelongsTo,
];

export const PROPERTY_DEFINITION_TYPES_TO_ENTRY_PROPERTY_TYPES: Record<AnyPropertyDefinitionEntityType, AnyEntryPropertyEntityType> = {
	[EntityType.PropertyDefinitionString]: EntityType.EntryPropertyString,
	[EntityType.PropertyDefinitionBoolean]: EntityType.EntryPropertyBoolean,
	[EntityType.PropertyDefinitionBelongsTo]: EntityType.EntryPropertyBelongsTo,
} as Record<AnyPropertyDefinitionEntityType, AnyEntryPropertyEntityType>;

const getRepositoryAsSelectOption = (repository: Repository): PropertyDefinitionSelectStringOptionEntry => ({
	id: `idRepositorySelectOption${repository.id}`,
	type: EntityType.PropertyDefinitionSelectStringOption,
	parentId: 'SYSTEM',
	label: repository.title,
	value: repository.id,
});

export const GENERIC_ENTRY_VIEW_ADD_ENTRY_REPOSITORY_OPTIONS: PropertyDefinitionSelectStringOptionEntry[] = [
	getRepositoryAsSelectOption(NOTE_REPOSITORY),
	getRepositoryAsSelectOption(REPOSITORY_REPOSITORY)
];

export const REPOSITORY_TEMPLATE_VIEW_ADD_ENTRY_REPOSITORY_OPTIONS: PropertyDefinitionSelectStringOptionEntry[] = [
	getRepositoryAsSelectOption(TEMPLATE_PROPERTY_STRING_REPOSITORY),
	getRepositoryAsSelectOption(TEMPLATE_PROPERTY_BOOLEAN_REPOSITORY),
	getRepositoryAsSelectOption(TEMPLATE_PROPERTY_BELONGS_TO_REPOSITORY),
	getRepositoryAsSelectOption(TEMPLATE_PROPERTY_HAS_REPOSITORY),
];

export const REPOSITORY_ENTRIES_VIEW_ADD_ENTRY_REPOSITORY_OPTIONS: PropertyDefinitionSelectStringOptionEntry[] = [
	getRepositoryAsSelectOption(REPOSITORY_ENTRY_REPOSITORY),
];

export const SWAP_TOKEN_URL_PATH = 'auth/swapToken';
export const AUTH401_ACTION_TYPE = 'auth/401';
