import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
	EntityType,
	Entry,
	FormData,
	FormSelectOptions,
	PropertyDefinition, PropertyDefinitionBelongsTo, PropertyDefinitionHas,
	PropertyDefinitionSelectStringOptionEntry,
	Repository,
	RepositoryEntry
} from '../types';
import {RootState} from '../store';
import {PROPERTY_DEFINITION_TYPES, REPOSITORY_ENTRY_ENTITY_TYPES_BY_SYSTEM_REPOSITORY_IDS} from '../constants';
import {
	sortEntryByRank,
	getEditFormTemplatePropertiesForEntityType,
	getTemplatePropertiesBySystemRepositoryId, isCustomRepository
} from '../common/util';
import {selectAllEntries} from './contentSlice';

interface FormInitPayload {
	formTemplateProperties: PropertyDefinition[],
	formSelectOptions: FormSelectOptions,
	formData: FormData,
}

export interface FormDataState {
  formTemplateProperties: PropertyDefinition[];
  formSelectOptions: FormSelectOptions;
  formRepository: Repository | null;
  formData: FormData;
  addFormRepositoryOptions: PropertyDefinitionSelectStringOptionEntry[];
	addFormRepository: Repository | null;
	addFormLinkedProperty: PropertyDefinitionBelongsTo | PropertyDefinitionHas | null;
	addFormLinkedEntryId: string | null;
  editFormEntry: Entry | null;
  confirmDeleteDialogEntry: Entry | null;
}

export interface OpenAddFormProps {
	repository: Repository;
	linkToProperty?: PropertyDefinitionBelongsTo | PropertyDefinitionHas;
	linkToEntryId?: string;
}

const initialState: FormDataState = {
	formTemplateProperties: [],
	formSelectOptions: {},
	formRepository: null,
	formData: {},
	addFormRepositoryOptions: [],
	addFormRepository: null,
	addFormLinkedProperty: null,
	addFormLinkedEntryId: null,
	editFormEntry: null,
	confirmDeleteDialogEntry: null,
};

const formDataSlice = createSlice({
	name: 'formData',
	initialState,
	reducers: {
		openAddForm(state, action: PayloadAction<OpenAddFormProps>) {
			const {repository, linkToProperty, linkToEntryId} = action.payload;
			state.addFormRepository = repository;
			if (linkToProperty && linkToEntryId) {
				state.addFormLinkedProperty = linkToProperty;
				state.addFormLinkedEntryId = linkToEntryId;
			}
		},
		closeAddForm(state) {
			state.addFormRepository = null;
			state.addFormLinkedProperty = null;
			state.addFormLinkedEntryId = null;
		},
		formInit(state, action: PayloadAction<FormInitPayload>) {
			const {
				formTemplateProperties,
				formSelectOptions,
				formData,
			} = action.payload;
			state.formTemplateProperties = formTemplateProperties;
			state.formSelectOptions = formSelectOptions;
			state.formData = formData;
		},
		formDataSetState(state, action: PayloadAction<FormData>) {
			state.formData = action.payload;
		},
		addFormRepositoryOptionsSetState(state: FormDataState, action: PayloadAction<PropertyDefinitionSelectStringOptionEntry[]>) {
			state.addFormRepositoryOptions = action.payload;
		},
		editFormEntrySetState(state: FormDataState, action: PayloadAction<Entry | null>) {
			state.editFormEntry = action.payload;
		},
		formRepositorySetState(state, action: PayloadAction<Repository>) {
			state.formRepository = action.payload;
		},
		confirmDeleteDialogEntrySetState(state: FormDataState, action: PayloadAction<Entry | null>) {
			state.confirmDeleteDialogEntry = action.payload;
		},
		resetState() {
			return initialState;
		}
	},
});

export const {
	formDataSetState,
	formRepositorySetState,
	formInit,
	addFormRepositoryOptionsSetState,
	openAddForm,
	closeAddForm,
	editFormEntrySetState,
	confirmDeleteDialogEntrySetState,
	resetState,
} = formDataSlice.actions;

export default formDataSlice.reducer;

const selectFormDataState = (state: RootState) => state.formData;

/**
 * generic selectors
 */

export const selectFormData = createSelector([selectFormDataState], (state: FormDataState) => state.formData);

export const selectFormSelectOptions = createSelector([selectFormDataState], (state: FormDataState) => state.formSelectOptions);

export const selectFormTemplateProperties = createSelector([selectFormDataState], (state: FormDataState) => state.formTemplateProperties);

/**
 * use-case specific selectors
 */

export const selectFormEntityTypeOptions = createSelector([selectFormDataState], (state: FormDataState) => state.addFormRepositoryOptions);

export const selectAddFormRepository = createSelector([selectFormDataState], (state: FormDataState) => state.addFormRepository);
export const selectIsAddDialogOpen = createSelector([selectFormDataState],(state: FormDataState) => Boolean(state.addFormRepository));
export const selectAddFormRepositoryPropertyDefinitions = createSelector([selectAddFormRepository, selectAllEntries], (addFormRepository, allEntries) => {
	if (!addFormRepository) {
		return [];
	} else if (isCustomRepository(addFormRepository.id)) {
		const [firstPropertyDef, ...restDefs] = allEntries
			.filter((entry) => PROPERTY_DEFINITION_TYPES.includes(entry.type) && entry.parentId === addFormRepository.id)
			.sort(sortEntryByRank);
		return (firstPropertyDef ? [{...firstPropertyDef, autoFocus: true}, ...restDefs] : []) as PropertyDefinition[];
	} else {
		return getTemplatePropertiesBySystemRepositoryId(addFormRepository.id);
	}
});
export const selectAddFormRepositoryEntries = createSelector([selectAddFormRepository, selectAllEntries], (addFormRepository, allEntries) => {
	if (!addFormRepository) {
		return [];
	}
	return allEntries.filter((entry) => entry.type === EntityType.RepositoryEntry && entry.repositoryId === addFormRepository.id) as RepositoryEntry[];
});
export const selectAddFormRepositoryEntriesMaxRank = createSelector([selectAddFormRepositoryEntries], (entries: Entry[]) => {
	return entries.reduce((acc, record) => record.rank && record.rank > acc ? record.rank : acc, 0);
});
export const selectAddFormRepositoryEntryType = createSelector([selectAddFormRepository], (addFormRepository) => {
	return addFormRepository ? REPOSITORY_ENTRY_ENTITY_TYPES_BY_SYSTEM_REPOSITORY_IDS[addFormRepository.id] : null;
});
export const selectAddFormLinkedProperty = createSelector([selectFormDataState], (state: FormDataState) => state.addFormLinkedProperty);
export const selectAddFormLinkedEntryId = createSelector([selectFormDataState], (state: FormDataState) => state.addFormLinkedEntryId);

export const selectEditFormEntry = createSelector([selectFormDataState], (state: FormDataState) => state.editFormEntry);
export const selectIsEditDialogOpen = createSelector([selectEditFormEntry], (editFormEntry) => Boolean(editFormEntry));
export const selectEditFormEntryPropertyDefinitions = createSelector([selectEditFormEntry, selectAllEntries], (editFormEntry, allEntries) => {
	if (!editFormEntry) {
		return [];
	}
	if (editFormEntry.type === EntityType.RepositoryEntry) {
		const [firstPropertyDef, ...restDefs] = allEntries
			.filter((entry) => PROPERTY_DEFINITION_TYPES.includes(entry.type) && entry.parentId === editFormEntry.repositoryId)
			.sort(sortEntryByRank);
		return (firstPropertyDef ? [{...firstPropertyDef, autoFocus: true}, ...restDefs] : []) as PropertyDefinition[];
	} else {
		return getEditFormTemplatePropertiesForEntityType(editFormEntry.type);
	}
});

export const selectConfirmDeleteDialogEntry = createSelector([selectFormDataState], (state: FormDataState) => state.confirmDeleteDialogEntry);
export const selectIsConfirmDeleteDialogOpen = createSelector([selectConfirmDeleteDialogEntry], (confirmDeleteDialogEntry) => Boolean(confirmDeleteDialogEntry));

export const selectIsAnyDialogOpen = createSelector([selectIsAddDialogOpen, selectEditFormEntry, selectConfirmDeleteDialogEntry], (isAddDialogOpen, editFormEntry, confirmDeleteDialogEntry) => isAddDialogOpen || Boolean(editFormEntry) || Boolean(confirmDeleteDialogEntry));
