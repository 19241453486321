import React, {MutableRefObject, useCallback, useEffect, useRef} from 'react';
import {useDispatch} from 'react-redux';
import {useAppSelector} from '../hooks';
import {
	confirmDeleteDialogEntrySetState, editFormEntrySetState,
	selectFormEntityTypeOptions,
	selectIsAnyDialogOpen
} from '../containers/formDataSlice';
import {Entry, PressedKey} from '../types';
import {useAddEntryForm} from './AddEntryForm';
import {navigateRollOutward, navigateRollInward} from '../containers/navigationSlice';

interface ContentNavigationKeyboardEventsProps {
	entries: Entry[];
	focusIdRef: MutableRefObject<string | null>;
	disableNesting?: boolean;
}

function useKeyboardEffects() {
	const keyboardEventsRef = useRef<Record<string, () => void>>({});

	const setKeyboardMappings = useCallback((mappings: Record<string, () => void> | null) => {
		keyboardEventsRef.current = mappings ?? {};
	}, []);

	const onKeyDown = useCallback((event: KeyboardEvent) => {
		const mappings = keyboardEventsRef.current;
		if (typeof mappings[event.code] === 'function') {
			mappings[event.code]();
		}
	}, []);

	useEffect(() => {
		window.addEventListener('keydown', onKeyDown);
		return () => {
			window.removeEventListener('keydown', onKeyDown);
		};
	}, []);

	return setKeyboardMappings;
}

export const useRepositoryKeyboardEffects = () => {
	const dispatch = useDispatch();
	const setKeyboardMappings = useKeyboardEffects();
	const isAnyDialogOpen = useAppSelector(selectIsAnyDialogOpen);
	const addFormRepositoryOptions = useAppSelector(selectFormEntityTypeOptions);
	const {openAddDialog: openEntryAddDialog} = useAddEntryForm();

	const handleKeyPressEscape = useCallback(() => {
		dispatch(navigateRollOutward());
	}, []);

	const handleKeyPressSpace = useCallback(() => {
		if (!isAnyDialogOpen) {
			openEntryAddDialog();
		}
	}, [isAnyDialogOpen, addFormRepositoryOptions]); // PS! Make sure these deps are also listed for the callback up the call chain!

	useEffect(() => {
		setKeyboardMappings({
			[PressedKey.Escape]: handleKeyPressEscape,
			[PressedKey.Space]: handleKeyPressSpace,
		});
		return () => {
			setKeyboardMappings(null);
		};
	}, [isAnyDialogOpen, addFormRepositoryOptions]);
};

export const useRepositoryEntryKeyboardEffects = () => {
	const dispatch = useDispatch();
	const setKeyboardMappings = useKeyboardEffects();
	const isAnyDialogOpen = useAppSelector(selectIsAnyDialogOpen);
	const addFormRepositoryOptions = useAppSelector(selectFormEntityTypeOptions);

	const handleKeyPressEscape = useCallback(() => {
		dispatch(navigateRollOutward());
	}, []);

	useEffect(() => {
		setKeyboardMappings({
			[PressedKey.Escape]: handleKeyPressEscape,
		});
		return () => {
			setKeyboardMappings(null);
		};
	}, [isAnyDialogOpen, addFormRepositoryOptions]);
};

export const useGenericEntryKeyboardEffects = ({ entries, focusIdRef, disableNesting }: ContentNavigationKeyboardEventsProps) => {
	const dispatch = useDispatch();
	const isAnyDialogOpen = useAppSelector(selectIsAnyDialogOpen);
	const addFormRepositoryOptions = useAppSelector(selectFormEntityTypeOptions);
	const setKeyboardMappings = useKeyboardEffects();
	const {openAddDialog} = useAddEntryForm();

	const handleKeyPressEscape = useCallback(() => {
		dispatch(navigateRollOutward());
	}, []);

	const handleKeyPressBackspace = useCallback(() => {
		const entry = entries.find((entry) => entry.id === focusIdRef.current);
		if (entry) {
			dispatch(confirmDeleteDialogEntrySetState(entry));
		}
	}, [entries, focusIdRef.current]); // PS! Make sure these deps are also listed for the callback up the call chain!

	const handleKeyPressEnter = useCallback(() => {
		if (!disableNesting && focusIdRef.current) {
			dispatch(navigateRollInward({ focusId: focusIdRef.current }));
		}
	}, [focusIdRef.current, disableNesting]); // PS! Make sure these deps are also listed for the callback up the call chain!

	const handleKeyPressSpace = useCallback(() => {
		if (focusIdRef.current) {
			const entry = entries.find((entry) => entry.id === focusIdRef.current);
			if (entry) {
				dispatch(editFormEntrySetState(entry));
			}
		} else if (!isAnyDialogOpen) {
			openAddDialog();
		}
	}, [focusIdRef.current, entries, isAnyDialogOpen, addFormRepositoryOptions]); // PS! Make sure these deps are also listed for the callback up the call chain!

	useEffect(() => {
		setKeyboardMappings({
			[PressedKey.Escape]: handleKeyPressEscape,
			[PressedKey.Backspace]: handleKeyPressBackspace,
			[PressedKey.Enter]: handleKeyPressEnter,
			[PressedKey.Space]: handleKeyPressSpace,
		});
		return () => {
			setKeyboardMappings(null);
		};
	}, [focusIdRef.current, entries, isAnyDialogOpen, addFormRepositoryOptions]);
};

export default useKeyboardEffects;
