import {createAction, createSlice} from '@reduxjs/toolkit';
import {RootState} from '../store';

interface NavigationState {
	navigateInwardTargetId: string | null;
	isNavigateOutwardInitiated: boolean;
	redirectToPath: string | null;
}

const initialState: NavigationState = {
	navigateInwardTargetId: null,
	isNavigateOutwardInitiated: false,
	redirectToPath: null,
};

const navigationSlice = createSlice({
	name: 'navigation',
	initialState,
	reducers: {
		navigateInwardTargetIdSetState(state, action) {
			state.navigateInwardTargetId = action.payload;
		},
		isNavigateOutwardInitiatedSetState(state, action) {
			state.isNavigateOutwardInitiated = action.payload;
		},
		redirectToPathSetState(state, action) {
			state.redirectToPath = action.payload;
		},
	},
});

export const {
	navigateInwardTargetIdSetState,
	isNavigateOutwardInitiatedSetState,
	redirectToPathSetState,
} = navigationSlice.actions;

export const navigateRollOutward = createAction('navigation/navigateRollOutward');

export const navigateRollInward = createAction<any>('navigation/navigateRollInward');

export const selectNavigateInwardTargetId = (state: RootState) => state.navigation.navigateInwardTargetId;

export const selectIsNavigateOutwardInitiated = (state: RootState) => state.navigation.isNavigateOutwardInitiated;

export const selectRedirectToPath = (state: RootState) => state.navigation.redirectToPath;

export default navigationSlice.reducer;
