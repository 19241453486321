import {EntityType, Repository, PropertyDefinition} from '../types';

export const NOTE_REPOSITORY: Repository = {
	id: 'idNoteRepository',
	parentId: null,
	type: EntityType.Repository,
	title: 'Entries',
	singular: 'Entry',
};

export const NOTE_REPOSITORY_TEMPLATE_PROPERTIES: PropertyDefinition[] = [
	{
		id: 'idNoteRepositoryTitleProperty',
		parentId: NOTE_REPOSITORY.id,
		type: EntityType.PropertyDefinitionString,
		slug: 'title',
		label: '',
		required: true,
		autoFocus: true,
		placeholder: 'Type away...️',
	}
];
