import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import contentReducer from './containers/contentSlice';
import authReducer from './containers/authSlice';
import navigationReducer from './containers/navigationSlice';
import formDataReducer from './containers/formDataSlice';
import repositoryViewReducer from './containers/repositoryViewSlice';

import mySaga from './containers/sagas';
import {api} from './services/api';
import authMiddleware from './auth.middleware';

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
	reducer: {
		[api.reducerPath]: api.reducer,
		auth: authReducer,
		content: contentReducer,
		navigation: navigationReducer,
		formData: formDataReducer,
		repositoryView: repositoryViewReducer,
	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware()
		.concat(sagaMiddleware)
		.concat(api.middleware)
		.concat(authMiddleware)
});

sagaMiddleware.run(mySaga);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
