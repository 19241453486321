import React, {useMemo} from 'react';
import {Container, useMediaQuery} from '@mui/material';
import {
	EntityType,
	Entry, PopulatedEntry,
	PropertyDefinition,
	Theme,
} from '../types';
import {
	REPOSITORIES_BY_ENTITY_TYPE,
	REPOSITORY_TEMPLATE_VIEW_ADD_ENTRY_REPOSITORY_OPTIONS
} from '../constants';
import AddEntryForm, {AddEntryFormDialog, useAddEntryForm} from '../components/AddEntryForm';
import EditEntryForm, {EditFormDialog, useEditForm} from '../components/EditEntryForm';
import ConfirmDeleteEntryDialog, {useConfirmDeleteEntryDialog} from '../components/ConfirmDeleteEntryDialog';
import {getRepositorySingleUnitLabel} from '../common/util';
import EntriesTable from '../components/EntriesTable';
import GenericListItemMenu, {useGenericListItemMenu} from '../components/GenericListItemMenu';
import {useAppSelector} from '../hooks';
import {selectRepositoryPropertyDefinitionsPopulated, selectRepositoryPropertyDefinitions} from './repositoryViewSlice';
import {selectAddFormRepository, selectIsAddDialogOpen} from './formDataSlice';
import {useContentNavigationEffects} from '../components/ContentNavigationEffects';
import {useRepositoryKeyboardEffects} from '../components/KeyboardEffects';

const RepositoryViewProperties = () => {
	useContentNavigationEffects();
	useRepositoryKeyboardEffects();

	const repositoryPropertyDefinitions = useAppSelector(selectRepositoryPropertyDefinitions);
	const repositoryPropertyDefinitionsPopulated = useAppSelector(selectRepositoryPropertyDefinitionsPopulated);
	const {openAddDialog, closeAddDialog, handleAddDialogValueChange, onAddDialogSubmit} = useAddEntryForm();
	const {isEditDialogOpen, editFormEntry, closeEditDialog, onEditFormSubmit} = useEditForm();
	const {isConfirmDeleteDialogOpen, confirmDeleteDialogEntry, closeConfirmDeleteDialog, submitConfirmDeleteDialog} = useConfirmDeleteEntryDialog();
	const {menuAnchorEl, setMenuAnchorEl, menuEntry, setMenuEntry, closeListItemMenu, handleMenuEditBtnClick, handleMenuDeleteBtnClick} = useGenericListItemMenu({ entries: repositoryPropertyDefinitions as Entry[] });
	const isSmScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
	const isAddDialogOpen = useAppSelector(selectIsAddDialogOpen);
	const addFormRepository = useAppSelector(selectAddFormRepository);

	const MODEL_DEFINITION_VIRTUAL_PROPERTY_DEFINITIONS = [{
		id: 'labelPropertyFieldDefinition',
		type: EntityType.PropertyDefinitionString,
		slug: 'label',
		label: 'Property',
	}, {
		id: 'slugPropertyFieldDefinition',
		type: EntityType.PropertyDefinitionString,
		slug: 'slug',
		label: 'Key',
	}] as PropertyDefinition[];

	const openListItemMenu = (el: Element, entry: Entry) => {
		setMenuEntry(entry as Entry);
		setMenuAnchorEl(el as any);
	};

	const editDialogTitle = useMemo(() => editFormEntry ? `Edit ${getRepositorySingleUnitLabel(REPOSITORIES_BY_ENTITY_TYPE[editFormEntry.type]).toLowerCase()}?` : '', [editFormEntry]);
	const confirmDeleteDialogTitle = useMemo(() => confirmDeleteDialogEntry ? `Delete ${getRepositorySingleUnitLabel(REPOSITORIES_BY_ENTITY_TYPE[confirmDeleteDialogEntry.type]).toLowerCase()}?` : '', [confirmDeleteDialogEntry]);

	return <>
		<Container maxWidth={false} disableGutters={isSmScreen}>
			<EntriesTable
				data={repositoryPropertyDefinitionsPopulated as PopulatedEntry[]}
				propertyDefinitions={MODEL_DEFINITION_VIRTUAL_PROPERTY_DEFINITIONS}
				openListItemMenu={openListItemMenu as any}
			/>
		</Container>
		<GenericListItemMenu
			menuAnchorEl={menuAnchorEl}
			menuEntry={menuEntry}
			closeMenu={closeListItemMenu}
			onDeleteBtnClick={handleMenuDeleteBtnClick}
			onEditBtnClick={handleMenuEditBtnClick}
		/>
		<AddEntryFormDialog
			open={isAddDialogOpen}
			onClose={closeAddDialog}
			onSubmit={onAddDialogSubmit}
			repositoryOptions={REPOSITORY_TEMPLATE_VIEW_ADD_ENTRY_REPOSITORY_OPTIONS}
			onRepositoryOptionChange={(repositoryId) => openAddDialog({ repositoryId })}
			title={addFormRepository ? `New ${(addFormRepository as {singular: string}).singular.toLowerCase()}` : ''}
		>
			<AddEntryForm
				onChange={handleAddDialogValueChange}
			/>
		</AddEntryFormDialog>
		<EditFormDialog
			open={isEditDialogOpen}
			handleClose={closeEditDialog}
			handleSubmit={onEditFormSubmit}
			title={editDialogTitle}
		>
			<EditEntryForm />
		</EditFormDialog>
		<ConfirmDeleteEntryDialog
			open={isConfirmDeleteDialogOpen}
			handleClose={closeConfirmDeleteDialog}
			handleSubmit={submitConfirmDeleteDialog}
			entry={confirmDeleteDialogEntry}
			title={confirmDeleteDialogTitle}
		/>
	</>;
};

export default RepositoryViewProperties;