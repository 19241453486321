import React from 'react';
import styled from '@emotion/styled';
import CssBaseline from '@mui/material/CssBaseline';
import {
	BrowserRouter as Router,
	Routes,
	Route,
} from 'react-router-dom';
import {Theme} from './types';
import {Helmet} from 'react-helmet';
import ContentWrapper from './containers/ContentWrapper';
import config from './common/config';
import {ThemeProvider} from './ThemeProvider';

const Page = styled.div`
  background-color: ${({ theme }) => (theme as Theme).palette.background.default};
  color: ${({ theme }) => (theme as Theme).palette.text.primary};
`;

function App() {
	return (
		<ThemeProvider>
			<CssBaseline />
			<Page>
				<Helmet>
					<meta charSet="utf-8" />
					<title>{config.appName}</title>
					<link rel="canonical" href="http://mysite.com/example" />
				</Helmet>
				<Router>
					<Routes>
						<Route path="/:id?" element={<ContentWrapper />} />
					</Routes>
				</Router>
			</Page>
		</ThemeProvider>
	);
}

export default App;
