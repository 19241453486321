import {useAuth0} from '@auth0/auth0-react';
import {useSwapTokenMutation, useGetUserInfoMutation} from '../services/accounts';
import {useEffect} from 'react';
import {useAppSelector} from '../hooks';
import {
	selectAccessToken,
	selectUserProfile,
	tokenSetState,
	resetState,
	selectIsAuthenticated
} from '../containers/authSlice';
import {getAccessToken} from '../cookies';
import {useDispatch} from 'react-redux';

type UseAuthReturnValues = any;

export const useAuthEffects = () => {
	const {isAuthenticated: auth0IsAuthenticated, getAccessTokenSilently, logout: auth0Logout, isLoading: auth0IsLoading} = useAuth0();
	const dispatch = useDispatch();
	const [swapTokenMutation, {error: swapTokenError}] = useSwapTokenMutation();
	const [getUserInfoMutation] = useGetUserInfoMutation();
	const userProfile = useAppSelector(selectUserProfile);
	const token = useAppSelector(selectAccessToken);

	const swapToken = async () => {
		const auth0Token = await getAccessTokenSilently();
		await swapTokenMutation({ auth0Token });
	};

	/**
	 * load access token into the state from cookie
	 */
	useEffect(() => {
		const cookieToken = getAccessToken();
		if (!token && cookieToken) {
			dispatch(tokenSetState(cookieToken));
		}
	}, [token]);

	/**
	 * clear auth0 session in case of unsuccessful token swap
	 */
	useEffect(() => {
		if (swapTokenError) {
			auth0Logout(process.env.NODE_ENV === 'development' ? { openUrl() {
				window.location.replace('localhost:3000');
			} } : undefined);
		}
	}, [swapTokenError]);

	/**
	 * 1. fetch user data if it's missing and internal token is present
	 * 2. swap auth0 token for internal token in case it's missing and auth0 token present
	 */
	useEffect(() => {
		if (userProfile) {
			return;
		} else if (token) {
			getUserInfoMutation(); // in case of an error, token gets removed (handled in mutation)
		} else if (auth0IsAuthenticated) {
			swapToken(); // in case of an error, auth0 token gets removed (by effect handler above)
		}
	}, [userProfile, token, auth0IsAuthenticated]);
};

const useAuth = (): UseAuthReturnValues => {
	const {loginWithRedirect, isAuthenticated: auth0IsAuthenticated, getAccessTokenSilently, logout: auth0Logout, isLoading: auth0IsLoading, user: auth0User} = useAuth0();
	const dispatch = useDispatch();
	const userProfile = useAppSelector(selectUserProfile);
	const isAuthenticated = useAppSelector(selectIsAuthenticated);
	const isAuthenticating = auth0IsLoading || auth0IsAuthenticated && !isAuthenticated;

	const logout = () => {
		auth0Logout();
		dispatch(resetState());
	};

	return {loginWithRedirect, getAccessTokenSilently, isAuthenticating, isAuthenticated, logout, userProfile};
};

export default useAuth;
