import React, {useMemo, useRef} from 'react';
import {Container, useMediaQuery} from '@mui/material';
import {Entry, Repository, Theme} from '../types';
import EntriesTable from '../components/EntriesTable';
import AddRepositoryEntryForm, {
	AddRepositoryEntryFormDialog,
	useAddRepositoryEntryForm
} from '../components/AddRepositoryEntryForm';
import {useAppSelector} from '../hooks';
import {selectRepositoryEntriesPopulated, selectRepositoryPropertyDefinitions} from './repositoryViewSlice';
import {useContentNavigationEffects} from '../components/ContentNavigationEffects';
import {useRepositoryKeyboardEffects} from '../components/KeyboardEffects';
import ConfirmDeleteEntryDialog, {useConfirmDeleteEntryDialog} from '../components/ConfirmDeleteEntryDialog';
import EditEntryForm, {EditFormDialog, useEditForm} from '../components/EditEntryForm';
import RepositoryEntriesListItemMenu from '../components/RepositoryEntriesListItemMenu';
import {AddButtonPadding} from '../components/AddButton';
import useNavigateToLocationId from '../components/NavigateToLocationId';
import {getRepositorySingleUnitLabel} from '../common/util';
import {useAddEntryForm} from '../components/AddEntryForm';

interface Props {
  repository: Repository;
}

const RepositoryViewEntries = ({repository}: Props) => {
	useContentNavigationEffects();
	useRepositoryKeyboardEffects();

	const repositoryPropertyDefinitions = useAppSelector(selectRepositoryPropertyDefinitions);
	const populatedEntries = useAppSelector(selectRepositoryEntriesPopulated);
	const {isAddDialogOpen, closeAddDialog} = useAddEntryForm();
	const {handleAddDialogSubmit, handleAddDialogValueChange} = useAddRepositoryEntryForm();
	const {isEditDialogOpen, editFormEntry, openEditDialog, closeEditDialog, onEditFormSubmitEntry} = useEditForm();
	const {isConfirmDeleteDialogOpen, confirmDeleteDialogEntry, openConfirmDeleteDialog, closeConfirmDeleteDialog, submitConfirmDeleteDialog} = useConfirmDeleteEntryDialog();
	const navigateToLocationId = useNavigateToLocationId();

	const isSmScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

	const menuAnchorEl = useRef<Element | null>(null);
	const [menuEntry, setMenuEntry] = React.useState<null | Entry>(null);

	const openListItemMenu = (el: Element, entry: Entry) => {
		setMenuEntry(entry);
		menuAnchorEl.current = el;
	};

	const closeListItemMenu = () => setMenuEntry(null);

	const handleMenuDeleteBtnClick = (id: string) => {
		closeListItemMenu();
		const entry = populatedEntries.find((entry) => entry.id === id);
		if (entry) {
			openConfirmDeleteDialog(entry as unknown as Entry);
		}
	};

	const handleMenuEditBtnClick = (id: string) => {
		closeListItemMenu();
		const repositoryEntry = populatedEntries.find((entry) => entry.id === id);
		if (repositoryEntry) {
			openEditDialog(repositoryEntry);
		}
	};

	const handleMenuGoToBtnClick = (id: string) => {
		closeListItemMenu();
		navigateToLocationId(id);
	};

	const editDialogTitle = useMemo(() => editFormEntry ? `Edit ${getRepositorySingleUnitLabel(repository).toLowerCase()}?` : '', [editFormEntry, repository]);
	const confirmDeleteDialogTitle = useMemo(() => confirmDeleteDialogEntry ? `Delete ${getRepositorySingleUnitLabel(repository).toLowerCase()}?` : '', [confirmDeleteDialogEntry, repository]);

	return <>
		<Container maxWidth={false} disableGutters={isSmScreen}>
			<EntriesTable
				propertyDefinitions={repositoryPropertyDefinitions}
				data={populatedEntries}
				openListItemMenu={openListItemMenu}
			/>
		</Container>
		<RepositoryEntriesListItemMenu
			menuEntry={menuEntry}
			menuAnchorEl={menuAnchorEl}
			closeMenu={closeListItemMenu}
			onGoToBtnClick={handleMenuGoToBtnClick}
			onEditBtnClick={handleMenuEditBtnClick}
			onDeleteBtnClick={handleMenuDeleteBtnClick}
		/>
		<AddButtonPadding />
		<AddRepositoryEntryFormDialog
			open={isAddDialogOpen}
			onClose={closeAddDialog}
			onSubmit={handleAddDialogSubmit}
			title={repository.singular ? `New ${repository.singular.toLowerCase()}` : ''}
		>
			<AddRepositoryEntryForm
				onChange={handleAddDialogValueChange}
			/>
		</AddRepositoryEntryFormDialog>
		<EditFormDialog
			open={isEditDialogOpen}
			handleClose={closeEditDialog}
			handleSubmit={onEditFormSubmitEntry}
			title={editDialogTitle}
		>
			<EditEntryForm />
		</EditFormDialog>
		<ConfirmDeleteEntryDialog
			open={isConfirmDeleteDialogOpen}
			handleClose={closeConfirmDeleteDialog}
			handleSubmit={submitConfirmDeleteDialog}
			entry={confirmDeleteDialogEntry}
			title={confirmDeleteDialogTitle}
		/>
	</>;
};

export default RepositoryViewEntries;