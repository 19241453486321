import {EntityType, Repository, PropertyDefinition} from '../types';

export const TEMPLATE_PROPERTY_STRING_REPOSITORY: Repository = {
	id: 'idPropertyDefinitionStringRepository',
	parentId: null,
	type: EntityType.Repository,
	title: 'Text properties',
	singular: 'Text property',
};

export const TEMPLATE_PROPERTY_STRING_TEMPLATE_PROPERTIES: PropertyDefinition[] = [{
	id: 'idPropertyDefinitionStringTemplateTitleProperty',
	parentId: TEMPLATE_PROPERTY_STRING_REPOSITORY.id,
	type: EntityType.PropertyDefinitionString,
	slug: 'label',
	label: 'Label',
	required: true,
	autoFocus: true,
}, {
	id: 'idPropertyDefinitionStringTemplateSlugProperty',
	parentId: TEMPLATE_PROPERTY_STRING_REPOSITORY.id,
	type: EntityType.PropertyDefinitionString,
	slug: 'slug',
	label: 'Key',
	required: true,
	disabled: true,
}, {
	id: 'idPropertyDefinitionStringTemplateRequiredProperty',
	parentId: TEMPLATE_PROPERTY_STRING_REPOSITORY.id,
	type: EntityType.PropertyDefinitionBoolean,
	slug: 'required',
	label: 'Required',
	required: true,
	defaultChecked: true,
}];
